import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userAddressList, userAddressDelete } from '../Actions/userActions';
import AddAddress from './AddAddress';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the default CSS for the confirm alert
import { confirmAlert } from 'react-confirm-alert';
function Address() {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin); // Get user info including token
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const dispatch = useDispatch();
  const { addresses, error, loading } = useSelector((state) => state.userAddresses);

  useEffect(() => {
    if (token) {
      dispatch(userAddressList(token));
    }
  }, [dispatch, token]);

  const toggleAdd = () => setIsAddOpen((prev) => !prev);

  const handleDelete = (id_shipping_address) => {
    confirmAlert({
      title: 'Confirm?',
      message: 'Are you sure you want to delet this address?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
       dispatch(userAddressDelete(token, id_shipping_address)).then(() => {
      dispatch(userAddressList(token)); // Refresh the address list after deletion
    });


  }
},
{
  label: 'No'
}
]
});
};


  return (
    <div>
      <div className='profCont'>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error}</div>}
        {addresses && addresses.data && addresses.data.length > 0 ? (
          addresses.data.map((address) => (
            <div key={address.id_shipping_address} className='addressCont'>
              <div>
                <h4>{address.title_address}</h4>
                <p>{address.address}</p>
              </div>
              <p
                className='addDelete'
                onClick={() => handleDelete(address.id)}
              >
                delete X
              </p>
            </div>
          ))
        ) : (
          <div className='addressContEmpty'>
            <h4>No addresses found.</h4>
          </div>
        )}
      </div>
      <AddAddress isOpen={isAddOpen} toggleAdd={toggleAdd} />
      <button onClick={toggleAdd} className='updateBtn'>
        Add new address
      </button>
    </div>
  );
}

export default Address;
