import React, { useState, useEffect } from 'react';
import { motion as m } from 'framer-motion';
import './ShareExperience.css';
import { contactForm,resetContactForm } from '../Actions/userActions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const overlaysVariants = {
  open: { opacity: 1, display: 'flex' },
  closed: { opacity: 0, transitionEnd: { display: 'none' } },
};

const getOverlayVariants = () => {
  if (window.innerWidth >= 600) {
    return {
      open: { left: '50%' },
      closed: { left: '100%' },
    };
  } else {
    return {
      open: { left: '0%' },
      closed: { left: '100%' },
    };
  }
};

function ShareExperience() {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [overlayVariants, setOverlayVariants] = useState(getOverlayVariants());
  const [fileName, setFileName] = useState('No file chosen'); // State to hold the file name
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [subject] = useState('instagram');
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const phone = '';

  const { loading, error, success } = useSelector((state) => state.contactForm);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!name || !email || !subject || !content) {
      alert('Please fill in all required fields.');
      return;
    }
    dispatch(contactForm(name, phone, email, subject, content, file));
  };

  const toggleOverlay = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0] ? e.target.files[0].name : 'No file chosen'); // Update the file name
  };

  useEffect(() => {
    const handleResize = () => {
      setOverlayVariants(getOverlayVariants());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (success) {
      setIsOverlayOpen(false);
      setSuccessMessage('We received your message.');
      dispatch(resetContactForm());
      setTimeout(() => setSuccessMessage(''), 3000);
    }
    if (error) {
      setErrorMessage(error);
      setTimeout(() => setErrorMessage(''), 3000);
    }
  }, [success, error]);

  return (
    <div className="share-experience">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img style={{ width: '100px', height: '100px' }} src="/images/IGB.png" alt="Instagram Logo" />
        <h2>{t('Share Your Experience #Parvari.ae')}</h2>
      </div>
      <div>
        <p className="share-experience-p">
          {t('Post your #Parvari.ae on Instagram or Twitter, or use the button below. 5 users will get X% discount on their next cart every month.')}
        </p>
      </div>

      <button className="share-experience-btn" onClick={toggleOverlay}>{t('Upload your photo')}</button>

      <m.div
        animate={isOverlayOpen ? 'open' : 'closed'}
        variants={overlaysVariants}
        transition={{ duration: 0.75 }}
        className={`overlayPh ${isOverlayOpen ? 'photo-overlay' : ''}`}
      >
        <m.div
          animate={isOverlayOpen ? 'open' : 'closed'}
          variants={overlayVariants}
          transition={{ duration: 0.75 }}
          className="overlay-content"
        >
          <div className="overlay-panel">
            <p onClick={toggleOverlay} className="closeExp">X close</p>
            <form onSubmit={submitHandler} className="sharePhoto">
              <label>User Name</label>
              <input onChange={(e) => setName(e.target.value)} />
              <label>Email Address</label>
              <input onChange={(e) => setEmail(e.target.value)} />
              <label>Content</label>
              <textarea onChange={(e) => setContent(e.target.value)} style={{ resize: 'none' }} />
              <label htmlFor="subject">File:</label>
              <div className="file-input-container">
                <input onChange={handleFileChange} type="file" id="file" name="file" className="file-input" />
                <span className="file-name">{fileName}</span>
                <label htmlFor="file" className="file-button">Choose File</label>
              </div>

              <button className="uplBtn" disabled={loading}>Upload</button>
            </form>
          </div>
        </m.div>
      </m.div>

      {successMessage && (
        <m.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className="success-message"
        >
          {successMessage}
        </m.div>
      )}
      {errorMessage && (
        <m.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className="error-message"
        >
          {errorMessage}
        </m.div>
      )}
    </div>
  );
}

export default ShareExperience;
