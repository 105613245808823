import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listProducts } from '../Actions/productActions';
import { listOrder } from '../Actions/cartActions';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import './OrderDetails.css';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import CancelOverlay from '../components/CancelOverlay'; // Adjust the import path based on your project structure



const OrderDetails = () => {
  const { t, i18n } = useTranslation(); 
  const { id_order } = useParams();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';
  const [showCancelOverlay, setShowCancelOverlay] = useState(false);


  useEffect(() => {
    if (token) {
      dispatch(listOrder(token));
      dispatch(listProducts());
    }
  }, [dispatch, token]);

  const handleCancelOrder = () => {
    setShowCancelOverlay(true);
  };

  const handleCloseCancelOverlay = () => {
    setShowCancelOverlay(false);
  };

  const ordersList = useSelector((state) => state.ordersList);
  const { loading, success, orders } = ordersList;

  const productList = useSelector((state) => state.productList);
  const { products, loading: productLoading, error: productError } = productList;

  if (loading || productLoading) {
    return <div>Loading...</div>;
  }

  if (!success || !orders || !orders.data || !orders.data['order detail']) {
    return <div>No order data available</div>;
  }

  if (productError) {
    return <div>Error loading products</div>;
  }

  const orderDetails = orders.data['order detail'].filter(order => order.order.order_id === id_order);

  if (orderDetails.length === 0) {
    return <div>Order not found</div>;
  }

  const orderInfo = orderDetails[0].order; // Get order info from the first matching detail
  const { shipping_address } = orderInfo;

  const getOrderProduct = (sku, colorId) => {
    const product = products?.find((p) =>
      p.storge.some((storage) => storage.SKU === sku)
    );

    if (product) {
      const media = product.media?.find(
        (mediaItem) => mediaItem.color.id === colorId
      );
      return {
        title: product.title_en,
        image: media ? media.image1 : null
      };
    }
    return { title: 'Product title not available', image: null };
  };

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className='order-details-container'>

        <div className="order-details-det">
          <div className="order-status">
         <div className='orderTit'>
          <h3>Order Status</h3>
          </div>
          <div className='deliveStatus'>
          <div className={`status-item ${orderInfo.is_paid ? 'active' : ''}`}>
              <span className="status-label">{t('Order processed')}</span>
              {orderInfo.is_paid ?
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
<path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"></path>
</svg> : ""}
            </div>
          <div className={`status-item ${orderInfo.packaging ? 'active' : ''}`}>
              <span className="status-label">{t('Packaging')}</span>
              {orderInfo.packaging ?
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
<path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"></path>
</svg> : ""}
            </div>
      
            <div className={`status-item ${orderInfo.delivered ? 'active' : ''}`}>
              <span className="status-label">{orderInfo.delivered ? t('Delivered') : 'Delivering... '}</span>
            {orderInfo.delivered ?
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
<path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"></path>
</svg> : ""}
            </div></div>
            <div className='deliveIcons'>
            <img src='/images/order-processed.png' />
              <img src='/images/package.png' />
              <img src='/images/delivery-truck.png' />
              <img src='/images/delivered.png' />
            </div>
            <div className={`statusBar ${orderInfo.delivered? 'active' : ''}`}>
              <div className={`bar ${orderInfo.is_paid ? 'active' : ''}`}></div>
              <div className={`bar ${orderInfo.packaging ? 'active' : ''}`}></div>
              <div className={`bar ${orderInfo.delivering ? 'active' : ''}`}></div>
              <div className='barfull'></div>
            </div>
              
          </div>

          <div className='orderProds'>
            <div style={{paddingLeft:'10px'}}>
              <h3>Order Details</h3>
              <p> <strong>Shipped to "{shipping_address.title_address || 'N/A'}" address</strong></p>
              <div style={{display:'flex', gap:'50px'}}>
              <p><strong>Order ID:</strong> {orderInfo.order_id}</p>
              <p><strong>Total:</strong> {orderInfo.total}$</p>
              </div>
              <p><strong>Payment Date:</strong> {orderInfo.payment_date || 'N/A'}</p>
      
            </div>
            {!orderInfo.cancel ? ( // Render cancel button if order is not canceled
              <button className="cancelOrder" onClick={handleCancelOrder}>Cancel Order</button>
            ) : ( // Render cancellation message if order is canceled
              <p className="orderCanceledMessage">This order has been canceled.</p>
            )}            <div className='orderImages'>
              {orderDetails.map((orderDetail, index) => {
                const { SKU, colorcount } = orderDetail.storage;
                const { title, image: productImage } = getOrderProduct(SKU, colorcount.id);
                const { storage, count, finalprice } = orderDetail;

                return (
                  <div key={index} className="product-detail-ord">
                    <div className="image-container">
                      {productImage ? (
                        <img className='orderImg' src={`${API_BASE_URL}${productImage}`} alt={title} />
                      ) : (
                        <p>Product image not available</p>
                      )}
                      <p className="image-text">{storage.colorcount.title_en} {title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
       {showCancelOverlay  && (
        <CancelOverlay
          onClose={handleCloseCancelOverlay}
          delivered={orderInfo.delivered&&orderInfo.delivering}
          delivering={orderInfo.delivering&&!orderInfo.delivered}
          orderId={id_order} // Pass order_id as a prop
          token={token}   
        />
      )}
    </div>
  );
};

export default OrderDetails;
