import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div>
      <select className='langSwitch' onChange={handleChange} defaultValue={i18n.language}>
        <option value="en">English</option>
        <option value="ar">عربیه</option>
        {/* Add more options for other languages */}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
