import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';

function Categories() {
  const { categories } = useSelector(state => state.categoryList);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';

  // Function to preload images
  const preloadImages = async () => {
    if (!categories || categories.length === 0) return;

    // Preload all images
    const imagePromises = categories.map(category => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = `${API_BASE_URL}${category.image_2}`;
      });
    });

    // Wait for all images to load
    await Promise.all(imagePromises);
    setImagesLoaded(true);
  };

  const handleSlideChange = (swiper) => {
    setImageOpacity(0); // Start fade-out effect
    setTimeout(() => {
      setCurrentCategoryIndex(swiper.realIndex); // Update the index
      setImageOpacity(1); // Start fade-in effect
    }, 300); // Timeout should match the transition duration
  };

  useEffect(() => {
    console.log('Categories:', categories);
    if (categories && categories.length > 0) {
      setCurrentCategoryIndex(0);
      preloadImages(); // Preload images when categories are available
    }
  }, [categories]);

  return (
    <div className='mainCats'>
      {imagesLoaded && categories && categories.length > 0 && (
        <img 
          className='catsCaroImg' 
          src={`${API_BASE_URL}${categories[currentCategoryIndex]?.image_2}`}
          alt={categories[currentCategoryIndex]?.title_en} 
          style={{ opacity: imageOpacity, transition: 'opacity 0.3s ease' }} 
        />
      )}

      {categories && categories.length > 0 && (
        <Swiper
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={3}
          centeredSlides={true}
          slideToClickedSlide={true}
          className='image-slider'
          onSlideChange={handleSlideChange}
          breakpoints={{
            240: { // Adjust this value as needed for your design
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2.2,
            },
          }}
        >
          {categories.map((category) => (
            <SwiperSlide key={category.title_en} className='carousel-item'>
              <Link to={`/category/${category.title_en}`} className='category-container'>
                <div className='image-container-caro'></div>
                <div className='title-overlay'>
                  <div className='line'></div>
                  {category.title_en}
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}

export default Categories;