import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './popup.css';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [popups, setPopups] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';

  const fetchPopups = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/home/popup`);
      setPopups(response.data.data.popup);
    } catch (error) {
      console.error('Error fetching popups:', error);
    }
  };

  useEffect(() => {
    fetchPopups();
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const settings = {
    infinite: true,
    speed:1,
    autoplay: true,
    autoplaySpeed:2000,
    slidesToShow: 1,
    slidesToScroll: 1,      
    pauseOnHover:false,

  };

  return (
    <div>
      {isOpen && popups.length > 0 && (
        <div className="popup">
          <Slider {...settings}>
            {popups.map((popup, index) => (
              <div key={index} className="popup-item">
                <p className="popup-content">{popup.text_en}</p>
              </div>
            ))}
          </Slider>
          <button className="close-popup" onClick={handleClose}>X</button>
        </div>
      )}
    </div>
  );
};

export default Popup;
