import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import './Contact.css';
import Footer from '../components/Footer';
import { contactForm,resetContactForm } from '../Actions/userActions';
import { motion as m, AnimatePresence } from 'framer-motion';


function Contact() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('No file chosen');
  const [validationErrors, setValidationErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { loading, error, success } = useSelector((state) => state.contactForm);

  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = 'Name is required';
    if (!email) errors.email = 'Email is required';
    if (!subject) errors.subject = 'Subject is required';
    if (!content) errors.content = 'Content is required';
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('description', content);
    if (phone) formData.append('phone', phone);
    if (file) formData.append('file', file);

    dispatch(contactForm(name, phone, email, subject, content, file));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0] ? e.target.files[0].name : 'No file chosen');
  };

  useEffect(() => {
    if (success) {
      setSuccessMessage('We received your message.');
      setTimeout(() => {
        setSuccessMessage('');
        dispatch(resetContactForm());
        navigate('/');
      }, 3000);
    }
    if (error) {
      setErrorMessage(error);
      setTimeout(() => setErrorMessage(''), 3000);
    }
  }, [success, error, navigate]);

  return (
    <div>
      <div className="contact-container">
        <div className="nav-pop">
          <Popup />
          <Navbar />
        </div>
        <div className="contact-content">
          <form onSubmit={submitHandler} className="contact-form" encType="multipart/form-data">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {validationErrors.name && <span className="error">{validationErrors.name}</span>}

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {validationErrors.email && <span className="error">{validationErrors.email}</span>}

            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            {validationErrors.subject && <span className="error">{validationErrors.subject}</span>}

            <label htmlFor="file">File:</label>
            <div className="file-input-container">
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                className="file-input"
              />
              <span className="file-name">{fileName}</span>
              <label htmlFor="file" className="file-button">Choose File</label>
            </div>

            <label htmlFor="content">Content:</label>
            <textarea
              style={{resize:'none'}}
              id="content"
              name="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            {validationErrors.content && <span className="error">{validationErrors.content}</span>}

            <button type="submit" disabled={loading}>Save</button>
          </form>

          <div className="contact-info">
            <p className="contactTitle">Contact info</p>
            <p>For suggestions and information about us, please send email to:</p>
            <p>example@example.com</p>
            <p>For getting detailed information about your shipment, please send email to:</p>
            <p>example@example.com</p>

            <p className="contactTitle">Our Phones</p>
            <p>Customer service phone: xxxxxxxxxx</p>
            <p>Fax: xxxxxxxxx</p>
            <p>Office: xxxxxxxxx</p>

            <p className="contactTitle">Our Addresses</p>
            <p>Office: no x, avenue 1, street 1, city, country</p>
          </div>
        </div>
      </div>
      <Footer />

      <AnimatePresence>
        {successMessage && (
          <m.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="success-message"
          >
            {successMessage}
          </m.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {errorMessage && (
          <m.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="error-message"
          >
            {errorMessage}
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Contact;
