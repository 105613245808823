import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, updateCartItemQty } from '../Actions/cartActions';
import './cart.css';
import Footer from '../components/Footer';
import { useNavigate, Link } from 'react-router-dom';
import Login from '../components/Login';
import Categories from '../components/Categories';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function Cart() {
  const cart = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const checkoutPanelRef = useRef(null);
  const footerRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';

  const ShoppingHandler = () => {
    navigate('/Allproducts');
  };

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      try {
        const parsedUserInfo = JSON.parse(storedUserInfo);
        if (!userInfo) {
          dispatch({ type: 'USER_LOGIN_SUCCESS', payload: parsedUserInfo });
        }
      } catch (error) {
        console.error('Failed to parse userInfo from localStorage:', error);
      }
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (checkoutPanelRef.current && footerRef.current) {
          const footerRect = footerRef.current.getBoundingClientRect();
          const checkoutPanelRect = checkoutPanelRef.current.getBoundingClientRect();

          if (entry.isIntersecting) {
            checkoutPanelRef.current.style.position = 'absolute';
            checkoutPanelRef.current.style.top = `${footerRect.top - checkoutPanelRect.height - 50 + window.scrollY}px`;
          } else {
            checkoutPanelRef.current.style.position = 'fixed';
            checkoutPanelRef.current.style.top = '25%';
          }
        }
      },
      {
        threshold: 0
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, [dispatch, userInfo]);

  const removeFromCartHandler = (sku) => {
    dispatch(removeFromCart(sku));
  };

  const updateQtyHandler = (sku, qty, count) => {
    if (qty > 0 && qty <= count) {
      dispatch(updateCartItemQty(sku, qty));
    } else if (qty === 0) {
      confirmAlert({
        title: t('Confirm to delete'),
        message: t('Are you sure you want to remove this item from your cart?'),
        buttons: [
          {
            label: t('Yes'),
            onClick: () => dispatch({ type: 'CART_REMOVE_ITEM', payload: sku })
          },
          {
            label: t('No'),
            onClick: () => {}
          }
        ]
      });
    }
  };

  const checkoutHandler = () => {
    if (!userInfo || !userInfo.data) {
      setIsLoginOpen(true);
    } else {
      navigate('/checkout');
    }
  };

  useEffect(() => {
    if (userInfo) {
      setIsLoginOpen(false);
    }
  }, [userInfo]);

  const toggleLogin = () => setIsLoginOpen((prev) => !prev);

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div>
      {cart.cartItems.length === 0 ? (
        <>
          <Link to='/' className='logoCont'>
            <img className='checkOutLogo' src="/images/logotop.png" alt="Logo" />
          </Link>
          <h3 style={{ textAlign: 'center' }}>Your cart is empty add some products to your cart from categories below</h3>
          <Categories />
        </>
      ) : (
        <div className='whole'>
          <Link to='/' className='logoCont'>
            <img className='checkOutLogo' src="/images/logotop.png" alt="Logo" />
          </Link>
          <div className='fixtop'>
          <div className='stagesPhone'>
            <div className='stage'></div>
            
          </div>
          <button onClick={goBackHandler} className='back-button phone'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.828 11.9998L15.778 16.9498L14.364 18.3638L8 11.9998L14.364 5.63577L15.778 7.04977L10.828 11.9998Z" fill="black" />
            </svg>
          </button></div>
          <div className='checkout-process'>
            <h5 className='summarySelect'>1. Cart summary</h5>
            <h5>2. Shipping and payment</h5>
            <h5>3. Order confirmation</h5>
          </div>
          <div className='cartCheck'>
            <div className='container'>
              <div className='cart'>
                {cart.cartItems.map((item) => (
                  <div className='cartItem' key={item.sku}>
                    {item.media && item.media.length > 0 && (
                      <img
                        className='cartItemPic'
                        src={`${API_BASE_URL}${item.media.find((image) => image.color.title_en === item.color)?.image1}`}
                        alt={item.title_en}
                      />
                    )}
                    <div className='itemInfo'>
                      <div className='titleColor'>
                        <p className='itemTitle itemName'>{item.title_en}</p>
                        <div>
                          <p>{item.color} /</p>
                          <pre> {item.size}</pre>
                        </div>
                      </div>
                      <div className='titleColor'>
                        <p className='itemTitle itemQty'>Quantity</p>
                        <div className='qtyCont'>
                          <p onClick={() => updateQtyHandler(item.sku, item.qty - 1, item.count)}>-</p>
                          <p>{item.qty}</p>
                          <p onClick={() => updateQtyHandler(item.sku, item.qty + 1, item.count)}>+</p>
                        </div>
                      </div>
                      <div>
                        <div className='titleColor'>
                          <p className='itemTitle'>{item.price * item.qty} AED</p>
                          <p className='cartDelete' onClick={() => removeFromCartHandler(item.sku)}>Delete</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='checkout-panel' ref={checkoutPanelRef}>
                <div className='checkborder'>
                  <img className='checkLogo' src='./images/logoempty.png' alt="Checkout Logo" />
                </div>
                <div className='priceTotal'>
                  <p>Total</p>
                  <p>{cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)} AED</p>
                </div>
                <button onClick={checkoutHandler} className="checkout-button">Check Out</button>
                <p>or</p>
                <button className="shopping-button" onClick={ShoppingHandler}>Continue Shopping</button>
              </div>
            </div>
          </div>
          <div className='phonePanel'>
            <div style={{ display: 'flex' }}>
              <p>Total:</p>
              <p>{cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)}$</p>
            </div>
            <div style={{ display: 'flex' }}>
              <Link to='/' className="count-button-ph">{t('Continue Shopping')}</Link>
              <button onClick={checkoutHandler} className="checkout-button-ph">{t('Check out')}</button>
            </div>
          </div>
        </div>
      )}
      <Footer ref={footerRef} />
      <Login isOpen={isLoginOpen} toggleLogin={toggleLogin} />
    </div>
  );
}

export default Cart;
