import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Fixed the import
import { composeWithDevTools } from 'redux-devtools-extension';
import {
    productListReducer,
    productDetailsReducer,
    categoryListReducer,
    campaignListReducer
} from './reducers/productReducers';
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userAddressListReducer,
    contactFormReducer,
    changePasswordReducer,
    changeEmailReducer,
    activationLinkReducer
} from './reducers/userReducers';
import {
    cartReducer,
    addOrderReducer,
    listOrderReducer,
    couponCodeReducer
} from './reducers/cartReducers';

const reducer = combineReducers({
    
    productList: productListReducer,
    productDetails: productDetailsReducer,
    categoryList: categoryListReducer,
    cart: cartReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userAddresses: userAddressListReducer,
    addedOrder: addOrderReducer,
    ordersList: listOrderReducer,
    coupon: couponCodeReducer,
    contactForm: contactFormReducer,
    changePassword: changePasswordReducer,
    changeEmail: changeEmailReducer,
    CampaignList: campaignListReducer,
    activationLinkRequest: activationLinkReducer,

});

// Function to safely parse JSON from localStorage
const safeJSONParse = (item) => {
    try {
        return JSON.parse(localStorage.getItem(item));
    } catch (e) {
        console.error(`Error parsing ${item} from localStorage`, e);
        return null;
    }
};

const cartItemsFromStorage = safeJSONParse('cartItems') || [];
const userInfoFromStorage = safeJSONParse('userInfo') || null;
const ordersFromStorage = safeJSONParse('orderList') || [];

const initialState = {
    cart: { cartItems: cartItemsFromStorage },
    userLogin: { userInfo: userInfoFromStorage },
    ordersList: { orders: ordersFromStorage },

};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

store.subscribe(() => {
    localStorage.setItem('cartItems', JSON.stringify(store.getState().cart.cartItems));
    localStorage.setItem('userInfo', JSON.stringify(store.getState().userLogin.userInfo));
    localStorage.setItem('orderList', JSON.stringify(store.getState().ordersList.orders));
});

export default store;
