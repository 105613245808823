import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './navbar.css';
import Menu from './Menu';
import Login from './Login';
import { Link } from 'react-router-dom';
import { login } from '../Actions/userActions'; // Import your login action
import { useTranslation } from 'react-i18next';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector(state => state.userDetails);
  const { user } = userDetails;
  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;
  const dispatch = useDispatch();
  const{ t }= useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsAtTop(scrollTop === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      // Close the login overlay after successful login
      setIsLoginOpen(false);
    }
  }, [userInfo]);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const toggleLogin = () => setIsLoginOpen((prev) => !prev);

  const handleLogin = (email, password) => {
    dispatch(login(email, password));
    // Close login overlay
    setIsLoginOpen(false);
  };

  // Calculate total cart count
  const cartCount = cartItems.reduce((acc, item) => acc + item.qty, 0);

  return (
    <div className={`navbar ${isAtTop ? 'at-top' : 'not-at-top'}`}>
      <div onClick={toggleMenu} className='menu-cont'>
      <img src='/images/Menu.png' alt='Menu'  className="menu-ico" />
      <p  className="menu ">
        {t('Menu')}
      </p></div>
      <Link to="/" className="nav-logo">
        {isAtTop ? <img className='logotop' src="/images/logotop.png" alt="Top Logo" /> : <img className='logonottop' src="/images/logonottop.png" alt="Not Top Logo" />}
      </Link>
      <div className="login-cart">
        {userInfo ? (
          <Link to="/Profile"
          className="profile">
            {user && user.data ? user.data.user.first_name : 'Profile'}
            </Link>
        ) : (
          <p onClick={toggleLogin} className="login">
            Login
          </p>
        )}
        <Link to="/Cart" className="cart-border">
          <img className='cartLogo' src='/images/cart.png'/>
          <p className="cart-count">{cartCount}</p>
        </Link>
      </div>

      {/* Menu Overlay */}
      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

      {/* Login Overlay */}
      <Login isOpen={isLoginOpen} toggleLogin={toggleLogin} handleLogin={handleLogin} />
    </div>
  );
}

export default Navbar;
