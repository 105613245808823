import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Footer from '../components/Footer';
import Login from '../components/Login';
import { userAddressList } from '../Actions/userActions';
import { addOrder, couponCode, finalizeOrder, couponCheck, resetCart } from '../Actions/cartActions';
import AddAddress from '../components/AddAddress';

const stripePromise = loadStripe('pk_test_51MHIBFIrwHAwNwGyMnXUvhk4GypQ2W7iDr0ShymV4bvnTkj5wZQA8AyaJMrKWtLGC2Bhc2HIBIJCUIubEGfPjgde00H3OUbrHL'); // Replace with your Stripe public key

const Checkout = () => {
  const cart = useSelector(state => state.cart);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.userLogin);
  const { order } = useSelector(state => state.addedOrder);
  const [orderId, setOrderId] = useState(null); // New state variable for order_id
  const couponState = useSelector(state => state.coupon);
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const checkoutPanelRef = useRef(null);
  const footerRef = useRef(null);
  const navigate = useNavigate();
  const [hasCheckedLogin, setHasCheckedLogin] = useState(false);
  const { addresses, error: addressError, loading: addressLoading } = useSelector(state => state.userAddresses);
  const [orderCount, setOrderCount] = useState(0);
  const [selectedShipping, setSelectedShipping] = useState('standard');
  const [shippingCost, setShippingCost] = useState(5);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [couponSuccessMessage, setCouponSuccessMessage] = useState('');
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false); // State variable to control the visibility of the message
  const [status, setStatus] = useState('idle'); // New state variable to track the current status
  const [isFinalized, setIsFinalized] = useState(false); // State variable to track if the order is finalized
  const [addOrderFailed, setAddOrderFailed] = useState(false);
  const toggleAdd = () => setIsAddOpen((prev) => !prev);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://api.parvari.ae/';

  



  const shippingOptions = [
    { id: 'standard', label: 'Standard Shipping - AED 5', cost: 5 },
    { id: 'express', label: 'Express Shipping - AED 10', cost: 10 },
    { id: 'overnight', label: 'Overnight Shipping - AED 20', cost: 20 }
  ];

  useEffect(() => {
    if (!hasCheckedLogin && !userInfo) {
      const timeoutId = setTimeout(() => {
        if (!userInfo) {
          navigate('/cart');
        }
        setHasCheckedLogin(true);
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [userInfo, navigate, hasCheckedLogin]);
  useEffect(() => {
    if (order && order.order_id) {
      setOrderId(order.order_id);
    }
  }, [order]);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo && !userInfo) {
      dispatch({ type: 'USER_LOGIN_SUCCESS', payload: JSON.parse(storedUserInfo) });
    }
    

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (checkoutPanelRef.current && footerRef.current) {
          const footerRect = footerRef.current.getBoundingClientRect();
          const checkoutPanelRect = checkoutPanelRef.current.getBoundingClientRect();

          if (entry.isIntersecting) {
            checkoutPanelRef.current.style.position = 'absolute';
            checkoutPanelRef.current.style.top = `${footerRect.top - checkoutPanelRect.height - 50 + window.scrollY}px`;
          } else {
            checkoutPanelRef.current.style.position = 'fixed';
            checkoutPanelRef.current.style.top = '25%';
          }
        }
      },
      { threshold: 0 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, [dispatch, userInfo]);

  const validateForm = () => {
    const errors = {};

    if (!selectedAddress) {
      errors.selectedAddress = 'Shipping address is required';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const delay = (ms) => new Promise(res => setTimeout(res, ms));
  const calculateTotalAmount = (items) => {
    let totalAmount = subTotal + shippingCost - discount;
    totalAmount = Math.round(totalAmount * 100); // Convert to cents and round to the nearest integer
    return totalAmount;
  };
  const addOrdersToCart = async () => {
    setStatus('processing');
    const items = cart.cartItems.map(item => ({
      code_storage: item.sku,
      count: item.qty,
    }));

    setOrderCount(items.length);

    try {
      for (let i = 0; i < items.length; i++) {
        await dispatch(addOrder(token, items[i].code_storage, items[i].count, selectedAddress, shippingCost));
        await delay(100);
      }
    setStatus('checkout');

    } catch (error) {
      console.error('Failed to add orders to cart:', error);
      setStatus('failed'); // Set status to failed
      setAddOrderFailed(true);
      setShowMessage(true);

    }
    
  };

  useEffect(() => {
    if (status === 'checkout' && orderId) {
      dispatch(finalizeOrder(token, orderId, total));
      setShowMessage(true);
      setIsFinalized(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 4000);
    }
  }, [status, orderId, dispatch, cart.cartItems, token]);
  useEffect(() => {
    if (status === 'failed' && orderId) {
      dispatch(finalizeOrder(token, orderId, total));
      setShowMessage(true);
      setIsFinalized(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 4000);
    }
  }, [status, orderId, dispatch, cart.cartItems, token]);

  const createCheckoutSession = async (orderId, total) => {

    setStatus('processing'); // Set status to processing
    const formData = new FormData();
    formData.append('amount', total);
    formData.append('order_id', orderId);

    try {
      const response = await axios.post(`${API_BASE_URL}/payment/create-checkout-session/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(couponCheck(token,coupon))
      

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (result.error) {
        console.error(result.error.message);
        // Handle error
      }
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      // Handle error
    }

  };

  const handleButtonClick = async () => {
    setIsCheckoutDisabled(true);

    if (validateForm()) {
      if (status === 'idle') {
        await addOrdersToCart();
      } else if (status === 'checkout') {
        // Calculate total amount
        const total = calculateTotalAmount(cart.cartItems);

        // Proceed with checkout process using the orderId
        await createCheckoutSession(orderId, total);
      }
    }

    setIsCheckoutDisabled(false);
  };
  
  useEffect(() => {
    if (order && order.order_id) {
      console.log('Order changed, updating orderId:', order.order_id);
      setOrderId(order.order_id);
    }
  }, [order]);
  
  const handleShippingChange = (event) => {
    const selectedOption = shippingOptions.find(option => option.id === event.target.value);
    setSelectedShipping(selectedOption.id);
    setShippingCost(selectedOption.cost);
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  useEffect(() => {
    if (token) {
      dispatch(userAddressList(token));
    }
  }, [dispatch, token]);

  const handleCouponCode = async (e) => {
    e.preventDefault();
  
    if (!coupon.trim()) {
      return;
    }
  
    setCouponSuccessMessage('');
    setCouponErrorMessage('');

    
  
    const response = await dispatch(couponCode(token, coupon));
  
    if (response && response.type === 'COUPON_CODE_SUCCESS') {
      const discount = response.orders.data.coupon.discount;
      const minAmount = response.orders.data.coupon.min_amount;
      if (subTotal >= minAmount) {
        setCouponSuccessMessage(`$${discount} decreased from your cart price`);
        setTimeout(() => setCouponSuccessMessage(''), 3000);
      } else {
        setCouponErrorMessage('The minimum cart price condition is not met');
        setTimeout(() => setCouponErrorMessage(''), 3000);
      }
    } else if (response && response.type === 'COUPON_CODE_FAIL') {
      setCouponErrorMessage(response.payload);
      setTimeout(() => setCouponErrorMessage(''), 3000);
    }
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (couponState.success) {
      const discount = couponState.orders.data.coupon.discount;
      const minAmount = couponState.orders.data.coupon.min_amount;
      if (subTotal >= minAmount) {
        setCouponSuccessMessage(`${discount} % decreased from your cart price`);
        setTimeout(() => setCouponSuccessMessage(''), 3000);
      } else {
        setCouponErrorMessage('The minimum cart price condition is not met');
        setTimeout(() => setCouponErrorMessage(''), 3000);
      }
    } else if (couponState.error) {
      setCouponErrorMessage(couponState.error);
      setTimeout(() => setCouponErrorMessage(''), 3000);
    }
  }, [couponState]);

  const subTotal = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0);

  const discount = couponState.success && couponState.orders && couponState.orders.data && couponState.orders.data.coupon.min_amount <= subTotal
    ? (subTotal * couponState.orders.data.coupon.discount) / 100
    : 0;

  const total = subTotal + shippingCost - discount;

  return (
    <div>
      <div className='whole'>
        <Link to='/' className='logoCont'>
          <img className='checkOutLogo' src="/images/logotop.png" alt="Logo" />
        </Link>
        <div className='fixtop'>
        <div className='stagesPhone'>
          <div className='stage'></div>
          <div className='stage'></div>
        </div>
        <button onClick={goBackHandler} className='back-button phone'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black">
            <path d="M10.828 11.9998L15.778 16.9498L14.364 18.3638L8 11.9998L14.364 5.63577L15.778 7.04977L10.828 11.9998Z" />
          </svg>
        </button></div>
        <div className='checkout-process'>
          <h5><Link style={{textDecoration:'none',color:'black'}} to='/cart'>1. Cart summary</Link></h5>
          <h5 className='summarySelect'>2. Shipping and payment</h5>
          <h5>3. Order confirmation</h5>
        </div>
        <div className='check'>
          <h3>Shipping Address</h3>
          <select className='addSelect' onChange={handleAddressChange} value={selectedAddress}  disabled={isFinalized}
          >
            <option value=''>Select address</option>
            {addresses && addresses.data && addresses.data.length > 0 ? (
              addresses.data.map((address) => (
                <option key={address.id} value={address.id}>{address.title_address}</option>
              ))
            ) : (
<></>
            )}
          </select>
      <p onClick={toggleAdd} className='addcheckBtn'>
        + Add address
      </p>
          {errors.selectedAddress && <p className="error-pay">{errors.selectedAddress}</p>}
          <h3>Shipping Method</h3>
          <div className='shipping-options'>
            {shippingOptions.map(option => (
              <div key={option.id}>
                <input
                          disabled={isFinalized}

                  type="radio"
                  id={option.id}
                  name="shipping"
                  value={option.id}
                  checked={selectedShipping === option.id}
                  onChange={handleShippingChange}
                />
                <label htmlFor={option.id}>{option.label}</label>
              </div>
            ))}
          </div>
          <div className='checkout-panel' ref={checkoutPanelRef}>
            <div style={{ display: 'flex', width: '100%' }}>
              <input className='couponInp'
                 disabled={isFinalized}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder='Enter gift code'
              />
              <button  className='applyCoup'  disabled={isFinalized} onClick={handleCouponCode}>+</button>
            </div>
            {couponSuccessMessage && (
              <p className={`coup-success ${couponSuccessMessage && 'fade-out'}`}>{couponSuccessMessage}</p>
            )}
            {couponErrorMessage && (
              <p className={`coup-error ${couponErrorMessage && 'fade-out'}`}>{couponErrorMessage}</p>
            )}
            <div className='checkborder'>
              <div className='priceTotal'>
                <p>Sub total</p>
                <p>AED{subTotal.toFixed(2)}</p>
              </div>
              <div className='priceTotal'>
                <p>Shipping</p>
                <p>AED{shippingCost.toFixed(2)}</p>
              </div>
              <img className='checkLogo' src='./images/logoempty.png' alt='Logo' />
            </div>
            <div className='priceTotal'>
              <p>Total</p>
              <p>AED{total.toFixed(2)}</p>
            </div>
            <button
            id="checkout-button"
            className="checkout-button"
        disabled={isCheckoutDisabled}
        onClick={handleButtonClick}
      >
        {status === 'processing' ? 'Processing...' : status === 'failed' ? 'Reset your cart': status === 'checkout' ? 'Checkout' : 'Finalize Order'}
              </button>  
              {showMessage && (
        <div className={`message-container message-animation ${status === 'failed' ? 'orderError':""}`}>
        {status === 'checkout' ? (
          <p>Orders finalized. Now go to checkout.</p>
        ) : status === 'failed' ? (
          <p>Failed to add orders. Please reset your shopping cart</p>
        ) : null}
        </div>
      )}
          </div>
          <h3 className='phone'>Gift code</h3>
            <div className='coupPhone'>
              <input className='couponInp'
                 disabled={isFinalized}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder='Enter gift code'
              />
              <button className='applyCoup' onClick={handleCouponCode}>+</button>
              {couponSuccessMessage && (
                <p className={`coup-success ${couponSuccessMessage && 'fade-out'}`}>{couponSuccessMessage}</p>
              )}
              {couponErrorMessage && (
                <p className={`coup-error ${couponErrorMessage && 'fade-out'}`}>{couponErrorMessage}</p>
              )}
            </div>
        </div>
        <div className='phonePanel'>
          <div style={{ display: 'flex' }}>
          <button
          id="checkout-button"
          className="checkout-button-ph-full"
        disabled={isCheckoutDisabled}
        onClick={handleButtonClick}
      >
        {status === 'processing' ? 'Processing...' : status === 'checkout' ? 'Checkout' : 'Finalize Order'}      </button>
        <select className='phoneTotal'>
            <option>Subtotal : {subTotal.toFixed(2)} aed</option>
            <option>Shipping : {shippingCost.toFixed(2)} aed</option>
            <option>Total : {total.toFixed(2)} aed</option>
             </select>
          </div>

        </div>
      </div>
      <AddAddress isOpen={isAddOpen} toggleAdd={toggleAdd} />

      <Footer ref={footerRef} />
      <Login />
    </div>
  );
}

export default Checkout;
