import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,

    CAMPAIGN_LIST_REQUEST,
    CAMPAIGN_LIST_SUCCESS,
    CAMPAIGN_LIST_FAIL,
} from '../constants/productConstants';

const initialState = {
    campaigns: [],
    // other state properties
  };
  

export const productListReducer = (state = { loading: false, products: [] }, action) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true, products: [] };

        case PRODUCT_LIST_SUCCESS:
            return {
                loading: false,
                products: action.payload.data.product, // Update to access the products array
            };

        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const productDetailsReducer = (state = { loading: false, product: {} }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { loading: true, ...state };

        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload.data.product, // Update to access the products array
            };

        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const categoryListReducer = (state = { loading: false, categories: [] }, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { loading: true, categories: [] };

        case CATEGORY_LIST_SUCCESS:
            return {
                loading: false,
                categories: action.payload.data.category, 
            };

        case CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const campaignListReducer = (state = { loading: false, campaigns: [] }, action) => {
    switch (action.type) {
        case CAMPAIGN_LIST_REQUEST:
            return { loading: true, campaigns: [] };

        case CAMPAIGN_LIST_SUCCESS:
            return {
                loading: false,
                campaigns: action.payload.data // Update to access the products array
            };

        case CAMPAIGN_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};