import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, changeEmail } from '../Actions/userActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS for the confirm alert
import {
  CHANGE_PASSWORD_CLEAR_SUCCESS,
  CHANGE_EMAIL_CLEAR_SUCCESS
} from '../constants/userConstants'

function Preferences() {
    const [old_password, setOld_password] = useState('');
    const [new_password, setNew_password] = useState('');
    const [re_password, setRe_password] = useState('');
    const [email, setEmail] = useState('');
    const { userInfo } = useSelector(state => state.userLogin); // Get user info including token
    const token = userInfo && userInfo.data ? userInfo.data.token : null;
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});

    const passwordError = useSelector(state => state.changePassword.error);
    const passwordSuccess = useSelector(state => state.changePassword.success); // Get success message
    const emailError = useSelector(state => state.changeEmail.error);
    const emailSuccess = useSelector(state => state.changeEmail.success); // Get success message

    const clearSuccessMessage = (messageType) => {
        if (messageType === 'password') {
            dispatch({ type: CHANGE_PASSWORD_CLEAR_SUCCESS });
        } else if (messageType === 'email') {
            dispatch({ type: CHANGE_EMAIL_CLEAR_SUCCESS });
        }
    };

    useEffect(() => {
        if (passwordSuccess) {
            setTimeout(() => {
                clearSuccessMessage('password');
            }, 4000);
        }
        if (emailSuccess) {
            setTimeout(() => {
                clearSuccessMessage('email');
            }, 4000);
        }
    }, [passwordSuccess, emailSuccess]);

    const validateForm = () => {
        const errors = {};

        setErrors(errors);
        if (!new_password.trim()) {
            errors.new_password = 'This field is required';
        } else if (new_password.length < 8 || new_password.length > 20) {
            errors.new_passwordLength = 'Password should be between 8 and 20 characters';
        } else if (!/[A-Z]/.test(new_password) || !/\d/.test(new_password)) {
            errors.new_passwordFormat = 'Password should contain at least an uppercase letter and a digit';
        }

        if (new_password !== re_password) {
            errors.match = 'Passwords do not match.';
        }
        return Object.keys(errors).length === 0;
    };

    const passwordChangeHandler = (e) => {
        e.preventDefault();
        if (validateForm()) {
            confirmAlert({
                title: 'Confirm Password Change',
                message: 'Are you sure you want to change your password?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => dispatch(changePassword(token, old_password, new_password))
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    };

    const emailChangeHandler = (e) => {
        e.preventDefault();
        confirmAlert({
            title: 'Confirm to submit',
            message: 'You will be logged out of your account and you need to verify your new e-mail to login again. Are you sure you want to proceed?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dispatch(changeEmail(token, email))
                },
                {
                    label: 'No'
                }
            ]
        });
    };
    

    return (
        <div className='prefCont'>
            <div className='password'>
                <h4>Change Password</h4>
                <form className='profForm' onSubmit={passwordChangeHandler}>
                    <div>
                        <div className='profRow'>
                            <p>Current Password</p>
                            <input
                                type='password'
                                onChange={(e) => setOld_password(e.target.value)}
                                required
                                className='editInput'
                            />
                        </div>
                    </div>
                    <div>
                        <div className='profRow'>
                            <p>New Password</p>
                            <input
                                type='password'
                                onChange={(e) => setNew_password(e.target.value)}
                                required
                                className='editInput'
                            />
                        </div>
                        <div className='profRow'>
                            <p>Retype New Password</p>
                            <input
                                type='password'
                                onChange={(e) => setRe_password(e.target.value)}
                                required
                                className='editInput'
                            />
                        </div>
                    </div>
                    <p className="fieldError">{errors.new_passwordLength}</p>
                    <p className="fieldError">{errors.new_passwordFormat}</p>
                    <p className="fieldError">{errors.match}</p>
                    {passwordSuccess && <p className="successMessage">{passwordSuccess}</p>}
                    <p className="fieldError">{passwordError}</p>
                    <button type="submit" className='updateBtn'>Change Password</button>
                </form>
            </div>
            <div>
                <h4>Change E-mail</h4>
                <form onSubmit={emailChangeHandler} className='profForm'>
                    <div>
                        <div className='profRow'>
                            <p>New Email</p>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className='editInput'
                            />
                        </div>
                    </div>
                    <p className="fieldError">{emailError}</p>
                    {emailSuccess && <p className="successMessage">{emailSuccess}</p>}
                    <button type="submit" className='updateBtn'>Change E-mail</button>
                </form>
            </div>
        </div>
    );
}

export default Preferences;