import axios from "axios";
import {
     CART_ADD_ITEM,
     CART_REMOVE_ITEM,
     CART_UPDATE_ITEM_QTY,

     RESET_CART,

     ADD_ORDER_REQUEST,
     ADD_ORDER_SUCCESS,
     ADD_ORDER_FAIL,

     LIST_ORDER_REQUEST,
     LIST_ORDER_SUCCESS,
     LIST_ORDER_FAIL,

     DELETE_ORDER_REQUEST,
     DELETE_ORDER_SUCCESS,
     DELETE_ORDER_FAIL,

      FINALIZE_ORDER_REQUEST,
      FINALIZE_ORDER_SUCCESS,
      FINALIZE_ORDER_FAIL,

     COUPON_CODE_REQUEST,
     COUPON_CODE_SUCCESS,
     COUPON_CODE_FAIL,

     COUPON_CHECK_REQUEST,
     COUPON_CHECK_SUCCESS,
     COUPON_CHECK_FAIL,

     CANCEL_ORDER_REQUEST,
     CANCEL_ORDER_SUCCESS,
     CANCEL_ORDER_FAIL,




 } from "../constants/cartConstants";
 const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://api.parvari.ae/';


 const getErrorMessage = (error) => {
  if (error.response && error.response.data) {
      if (error.response.data.message) {
          return error.response.data.message;
      } else if (error.response.data) {
          return error.response.data;
      } else if (error.response.data.password) {
          return error.response.data.password;
      }
  }
  return 'An error occurred. Please try again later.';
};

export const addToCart = (id, qty, sku, color, size, price, title_en, count, media) => async (dispatch, getState) =>{
    const { data } = await axios.get(`${API_BASE_URL}/product/detail_product/${id}`);

    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            product: data._id,
            name: data.name,
            qty,
            sku,
            price,
            color,
            size,
            title_en,
            count,
            media,
           
        }
    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}
export const resetCart = () => (dispatch) => {
  dispatch({ type: RESET_CART });
  localStorage.removeItem('cartItems'); // Optionally clear localStorage related to cart
};

export const removeFromCart = (sku) => (dispatch, getState) => {
    dispatch({
      type: CART_REMOVE_ITEM,
      payload: sku
    });
  
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  };

  export const updateCartItemQty = (sku, qty) => (dispatch, getState) => {
    dispatch({
      type: CART_UPDATE_ITEM_QTY,
      payload: { sku, qty }
    });
  
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  };

  export const addOrder = (token, code_storage, count, id_shipping_address, shipping_cost) => async (dispatch) => {
    try {
      dispatch({ type: ADD_ORDER_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);
      formData.append('code_storage', code_storage);
      formData.append('count', count);
      formData.append('id_shipping_address', id_shipping_address);
      formData.append('shopping_cost', shipping_cost);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/orders/add_order`, formData, config);
  
      dispatch({
        type: ADD_ORDER_SUCCESS,
        payload: data,
      });
      localStorage.setItem('orderAdd', JSON.stringify(data));
    } catch (error) {
      console.error('Error in addOrder action:', error.response ? error.response.data : error.message);
  
      dispatch({
        type: ADD_ORDER_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
  
      throw new Error(error.response && error.response.data.message ? error.response.data.message : error.message);
    }
  };
  export const listOrder = (token) => async (dispatch) => {
    try {
      dispatch({ type: LIST_ORDER_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);

  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/orders/list_order`, formData, config);
  
      dispatch({
        type: LIST_ORDER_SUCCESS,
        payload: data,
      });
      localStorage.setItem('orderList', JSON.stringify(data));


    } catch (error) {
      console.error('Token validation error:', error.response ? error.response : error);
  
      dispatch({
        type: LIST_ORDER_FAIL,
        payload: getErrorMessage(error)
      });
  
      if (error.response && error.response.status === 401) {
        // Handle token expiration or invalid token here, e.g., redirect to login
      }
    }
  };

  export const deleteOrder = (token, id_order) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_ORDER_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);
      formData.append('id_order', id_order);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/orders/remove_order`, formData, config);
  
      dispatch({
        type: DELETE_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
  
      dispatch({
        type: DELETE_ORDER_FAIL,
        payload: getErrorMessage(error)
      });
  
      if (error.response && error.response.status === 401) {
        // Handle token expiration or invalid token here, e.g., redirect to login
      }
    }
  };

  export const finalizeOrder = (token, id_order, paid_amount) => async (dispatch) => {
    try {
      dispatch({ type: FINALIZE_ORDER_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);
      formData.append('id_order', id_order);
      formData.append('amount', paid_amount)
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/orders/finalzed_order`, formData, config);
  
      dispatch({
        type: FINALIZE_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
  
      dispatch({
        type: FINALIZE_ORDER_FAIL,
        payload: getErrorMessage(error)
      });
  
      if (error.response && error.response.status === 401) {
        // Handle token expiration or invalid token here, e.g., redirect to login
      }
    }
  };
  export const cancelOrder = (token, id_order, reason_for_cancellation, shippingـmethod, tracking_code) => async (dispatch) => {
    try {
      dispatch({ type: CANCEL_ORDER_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);
      formData.append('id_order', id_order);
      formData.append('reason_for_cancellation', reason_for_cancellation);
      formData.append('shippingـmethod', shippingـmethod);
      formData.append('tracking_code', tracking_code);
   
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/orders/cancel_order`, formData, config);
  
      dispatch({
        type: CANCEL_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
  
      dispatch({
        type: CANCEL_ORDER_FAIL,
        payload: getErrorMessage(error)
      });
  
      if (error.response && error.response.status === 401) {
        // Handle token expiration or invalid token here, e.g., redirect to login
      }
    }
  };

  export const couponCode = (token, coupon) => async (dispatch) => {
    try {
      dispatch({ type: COUPON_CODE_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);
      formData.append('coupon', coupon);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/coupon/check_coupon`, formData, config);
  
      dispatch({
        type: COUPON_CODE_SUCCESS,
        payload: data,
      });
  
    } catch (error) {
      dispatch({
        type: COUPON_CODE_FAIL,
        payload: getErrorMessage(error)
      });
    }
  };

  export const couponCheck = (token, coupon) => async (dispatch) => {
    try {
      dispatch({ type: COUPON_CHECK_REQUEST });
  
      const formData = new FormData();
      formData.append('token', token);
      formData.append('coupon', coupon);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          
        }
      };
  
      const { data } = await axios.post(`${API_BASE_URL}/coupon/check_user_coupon`, formData, config);
  
      dispatch({
        type: COUPON_CHECK_SUCCESS,
        payload: data,
      });
      

    } catch (error) {
  
      dispatch({
        type: COUPON_CHECK_FAIL,
        payload: getErrorMessage(error)
      });
  
      if (error.response && error.response.status === 401) {
        // Handle token expiration or invalid token here, e.g., redirect to login
      }
    }
  };