import React from 'react';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import './verification.css';
import Footer from '../components/Footer';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { requestNewActivationLink } from '../Actions/userActions'

function Verification() {
  const location = useLocation();
  const { search } = location;
  const userId = new URLSearchParams(search).get('user_id');
  const dispatch = useDispatch();

  const activationLinkRequest = useSelector((state) => state.activationLinkRequest);
  const { loading, success, error } = activationLinkRequest;

  const handleRequestNewLink = () => {
    dispatch(requestNewActivationLink(userId));
  };

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className='verification'>
        <div className='veri-panel'>
          <h2>You are almost done!</h2>
          <img className='tik' src='/images/tik.png' alt="Verification Tick" />
          <h3>We have sent you a confirmation email, Please check your inbox</h3>
          <p>Please click the link we have sent to your E-mail to activate your account</p>
          <p>You can use the button below to get a new activation link</p>
          <button className='veriBtn' onClick={handleRequestNewLink} disabled={loading}>
            {loading ? 'Sending...' : 'Send New Link'}
          </button>
          {success && <p>A new activation link has been sent to your email.</p>}
          {error && <p>Error: {error.message}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Verification;
