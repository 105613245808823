import axios from 'axios';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAIL,
    USER_LOGOUT,
    RESET_LOGIN_ERROR,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_ADDRESS_LIST_REQUEST,
    USER_ADDRESS_LIST_SUCCESS,
    USER_ADDRESS_LIST_FAIL,
    USER_ADD_ADDRESS_REQUEST,
    USER_ADD_ADDRESS_SUCCESS,
    USER_ADD_ADDRESS_FAIL,
    USER_DELETE_ADDRESS_REQUEST,
    USER_DELETE_ADDRESS_SUCCESS,
    USER_DELETE_ADDRESS_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,

    CHANGE_EMAIL_REQUEST,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_EMAIL_FAIL,

    CONTACT_FORM_REQUEST,
    CONTACT_FORM_SUCCESS,
    CONTACT_FORM_FAIL,
    RESET_CONTACT_FORM,

    REQUEST_NEW_ACTIVATION_LINK, 
    REQUEST_NEW_ACTIVATION_LINK_SUCCESS, 
    REQUEST_NEW_ACTIVATION_LINK_FAIL 

} from '../constants/userConstants';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/';


// Utility function for handling errors
const getErrorMessage = (error) => {
    if (error.response && error.response.data) {
        if (error.response.data.message) {
            return error.response.data.message;
        } else if (error.response.data.email) {
            return error.response.data.email;
        } else if (error.response.data.password) {
            return error.response.data.password;
        }
    }
    return 'An error occurred. Please try again later.';
};

export const resetContactForm = () => (dispatch) => {
    setTimeout(() => {
      dispatch({ type: RESET_CONTACT_FORM });
    }, 3000); // Reset the form after 3 seconds
  };

export const resetLoginError = () => (dispatch) => {
    setTimeout(() => {
        dispatch({ type: RESET_LOGIN_ERROR });
    }, 3000); // Clear the error after 3 seconds
};

export const login = (email, password, rememberMe) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST });

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/login_with_password`, formData, config);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        });

        if (rememberMe) {
            document.cookie = `userInfo=${JSON.stringify(data)};path=/;max-age=2592000`; // Cookie expires in 30 days
        } else {
            document.cookie = `userInfo=${JSON.stringify(data)};path=/`;
        }
    } catch (error) {
        console.error('Login error:', error.response ? error.response : error);

        dispatch({
            type: USER_LOGIN_FAIL,
            payload: getErrorMessage(error)
        });

        dispatch(resetLoginError());
    }
};

export const googleLogin = (g_id_token) => async (dispatch) => {
    try {
        dispatch({ type: GOOGLE_LOGIN_REQUEST });

        const formData = new FormData();
        formData.append('g_id_token', g_id_token);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/google_login`, formData, config);

        dispatch({
            type: GOOGLE_LOGIN_SUCCESS,
            payload: data
        });
        localStorage.setItem('userInfo', JSON.stringify(data));


    } catch (error) {
        console.error('Login error:', error.response ? error.response : error);

        dispatch({
            type: GOOGLE_LOGIN_FAIL,
            payload: getErrorMessage(error)
        });

        dispatch(resetLoginError());
    }
};

export const logout = () => (dispatch) => {
    document.cookie = 'userInfo=;path=/;max-age=0'; // Delete the cookie
    dispatch({ type: USER_LOGOUT });
};


export const register = (first_name, last_name, email, password, re_password) => async (dispatch) => {
    try {
        dispatch({ type: USER_REGISTER_REQUEST });

        const formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('email', email); // Assuming the backend expects the key 'email'
        formData.append('password', password);
        formData.append('re_password', re_password);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/register`, formData, config);

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        });

        localStorage.setItem('userRegisterInfo', JSON.stringify(data));
    } catch (error) {
        console.error('Register error:', error.response ? error.response : error);

        dispatch({
            type: USER_REGISTER_FAIL,
            payload: getErrorMessage(error)
        });
    }
};

export const userUpdate = (token, first_name, last_name, phone, gender, birthdate,country, onSuccess) => async (dispatch) => {
    try {
        dispatch({ type: USER_UPDATE_REQUEST });

        const formData = new FormData();
        formData.append('token', token); // Assuming the backend expects the key 'token'
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        if (phone) formData.append('phone', phone); // Only append if phone has a value
        if (gender) formData.append('gender', gender); // Only append if gender has a value
        if (birthdate) formData.append('birthdate', birthdate); // Only append if birthdate has a value
        if (country) formData.append('country', country); 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/editprofile_user`, formData, config);

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: data
        });
        dispatch(userDetails(token));

   

        if (onSuccess) {
            onSuccess(); // Call the success callback
        }
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: USER_UPDATE_FAIL,
            payload: getErrorMessage(error)
        });

        if (error.response && error.response.status === 401) {
            // Handle unauthorized error
        }
    }
};
export const userDetails = (token) => async (dispatch) => {
    try {
        dispatch({ type: USER_DETAILS_REQUEST });
        


      

        const formData = new FormData();
        formData.append('token', token ); // Assuming the backend expects the key 'token'



        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/editprofile_user`, formData, config);

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        });

      
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: USER_DETAILS_FAIL,
            payload: getErrorMessage(error)
        });

        if (error.response && error.response.status === 401) {
            dispatch(logout()); // Logout the user if the token is invalid
        }
    }
};

export const ForgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_PASSWORD_REQUEST });

        const formData = new FormData();
        formData.append('email', email);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/forget_password`, formData, config);

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data
        });


    } catch (error) {
        console.error('Login error:', error.response ? error.response : error);

        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: getErrorMessage(error)
        });

        dispatch(resetLoginError());
    }
};

export const userAddressList = (token) => async (dispatch) => {
    try {
        dispatch({ type: USER_ADDRESS_LIST_REQUEST });
        


      

        const formData = new FormData();
        formData.append('token', token ); // Assuming the backend expects the key 'token'


        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/shipping_address/list_user`, formData, config);

        dispatch({
            type: USER_ADDRESS_LIST_SUCCESS,
            payload: data
        });
        localStorage.setItem('userAddressList', JSON.stringify(data));

      
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: USER_ADDRESS_LIST_FAIL,
            payload: getErrorMessage(error)
        });

        if (error.response && error.response.status === 401) {
        
        }
    }
};

export const userAddressDelete = (token, id_shipping_address) => async (dispatch) => {
    try {
        dispatch({ type: USER_DELETE_ADDRESS_REQUEST });
        


      

        const formData = new FormData();
        formData.append('token', token ); 
        formData.append('id_shipping_address', id_shipping_address );


        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/shipping_address/remove_address`, formData, config);

        dispatch({
            type: USER_DELETE_ADDRESS_SUCCESS,
            payload: data
        });
        dispatch(userAddressList(token));


      
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: USER_DELETE_ADDRESS_FAIL,
            payload: getErrorMessage(error)
        });

        if (error.response && error.response.status === 401) {
        
        }
    }
};


export const userAddressAdd = (token, first_name, last_name, phone, country, city, zip_posteal_code,address ,title_address) => async (dispatch) => {
    try {
        dispatch({ type: USER_ADD_ADDRESS_REQUEST });
        


      

        const formData = new FormData();
        formData.append('token', token ); // Assuming the backend expects the key 'token'
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('phone', phone);
        formData.append('country', country);
        formData.append('city', city);
        formData.append('zip_posteal_code', zip_posteal_code);
        formData.append('address', address);
        formData.append('title_address', title_address);



        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/shipping_address/add_address`, formData, config);

        dispatch({
            type: USER_ADD_ADDRESS_SUCCESS,
            payload: data
           
        }
    );
    dispatch(userAddressList(token));

      
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: USER_ADD_ADDRESS_FAIL,
            payload: getErrorMessage(error)
        });

        if (error.response && error.response.status === 401) {
        
        }
    }
};

export const changePassword = (token, old_password, new_password) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_PASSWORD_REQUEST });
        
        const formData = new FormData();
        formData.append('token', token);
        formData.append('old_password', old_password);
        formData.append('new_password', new_password);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/change_password`, formData, config);

        // Extract the success message from the response
        const successMessage = data.message || 'Password changed successfully';

        dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: successMessage
        });
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: CHANGE_PASSWORD_FAIL,
            payload: getErrorMessage(error)
        });
    }
};

export const changeEmail = (token, email) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_EMAIL_REQUEST });
        
        const formData = new FormData();
        formData.append('token', token);
        formData.append('email', email);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/user/change_email`, formData, config);

        // Extract the success message from the response
        const successMessage = data.message || 'Email changed successfully';

        dispatch({
            type: CHANGE_EMAIL_SUCCESS,
            payload: successMessage
        });
        dispatch(logout());
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: CHANGE_EMAIL_FAIL,
            payload: getErrorMessage(error)
        });
    }
};


export const contactForm = ( name, phone,  email, subject, description, file,) => async (dispatch) => {
    try {
        dispatch({ type: CONTACT_FORM_REQUEST });
        


      
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('description', description);
        if (file) {
          formData.append('file', file);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const { data } = await axios.post(`${API_BASE_URL}/contact/contact_us`, formData, config);

        dispatch({
            type: CONTACT_FORM_SUCCESS,
            payload: data
           
        }
    );


      
    } catch (error) {
        console.error('Token validation error:', error.response ? error.response : error);

        dispatch({
            type: CONTACT_FORM_FAIL,
            payload: getErrorMessage(error)
        });

        if (error.response && error.response.status === 401) {
        
        }
    }
};



export const requestNewActivationLink = (userId) => async (dispatch) => {
    dispatch({ type: REQUEST_NEW_ACTIVATION_LINK });
    try {
      const response = await axios.post(`${API_BASE_URL}/user/request_activation_link`, { user_id: userId });
      dispatch({ type: REQUEST_NEW_ACTIVATION_LINK_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: REQUEST_NEW_ACTIVATION_LINK_FAIL, payload: error.response.data });
    }
  };



