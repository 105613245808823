import React, {useEffect} from 'react'
import Footer from "../components/Footer"
import Navbar from '../components/Navbar'
import Popup from '../components/Popup'
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';



function UserActivated() {
    const location = useLocation();
    const { search } = location;
    const userId = new URLSearchParams(search).get('user_id');
    const dispatch = useDispatch();


    

  return (
    <div>
        <div className='nav-pop'>
    <Popup />
         <Navbar />
 </div>
 <div className='orderCancel'>
    <h2>E-mail Verified</h2>
    <p>Now you can login to your account</p>
    <img style={{width:'25%', marginTop:'30px' }} src='/images/tik.png' />
<Link className='trackBtnCancel' to='/AllProducts'>Countinue Shopping
        </Link>
 </div>
      <Footer />
    </div>
  )
}

export default UserActivated
