export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR'

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST'
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS'
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'

export const USER_ADDRESS_LIST_REQUEST = 'USER_ADDRESS_LIST_REQUEST'
export const USER_ADDRESS_LIST_SUCCESS = 'USER_ADDRESS_LIST_SUCCESS'
export const USER_ADDRESS_LIST_FAIL = 'USER_ADDRESS_LIST_FAIL'

export const USER_ADD_ADDRESS_REQUEST = 'USER_ADD_ADDRESS_REQUEST'
export const USER_ADD_ADDRESS_SUCCESS = 'USER_ADD_ADDRESS_SUCCESS'
export const USER_ADD_ADDRESS_FAIL = 'USER_ADD_ADDRESS_FAIL'

export const USER_DELETE_ADDRESS_REQUEST = 'USER_DELETE_ADDRESS_REQUEST'
export const USER_DELETE_ADDRESS_SUCCESS = 'USER_DELETE_ADDRESS_SUCCESS'
export const USER_DELETE_ADDRESS_FAIL = 'USER_DELETE_ADDRESS_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

export const CHANGE_PASSWORD_CLEAR_SUCCESS = 'CHANGE_PASSWORD_CLEAR_SUCCESS';
export const CHANGE_EMAIL_CLEAR_SUCCESS = 'CHANGE_EMAIL_CLEAR_SUCCESS';

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAIL = 'CHANGE_EMAIL_FAIL'


export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST'
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS'
export const CONTACT_FORM_FAIL = 'CONTACT_FORM_FAIL'

export const RESET_CONTACT_FORM = 'CONTACT_FORM_FAIL'

export const REQUEST_NEW_ACTIVATION_LINK = 'REQUEST_NEW_ACTIVATION_LINK';
export const REQUEST_NEW_ACTIVATION_LINK_SUCCESS = 'REQUEST_NEW_ACTIVATION_LINK_SUCCESS';
export const REQUEST_NEW_ACTIVATION_LINK_FAIL = 'REQUEST_NEW_ACTIVATION_LINK_FAIL';