import React,{useEffect} from 'react'
import './Footer.css'
import { categoryList } from '../Actions/productActions';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';





const Footer = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { error, loading, categories } = useSelector(state => state.categoryList);
  const { t, i18n } = useTranslation(); // Use translation hook


  useEffect(() => {
    dispatch(categoryList());
  }, [dispatch]);

  let categoryItems;

  if (loading) {
    categoryItems = <p>Loading...</p>;
  } else if (error) {
    categoryItems = <p>Error: {error}</p>;
  }else {
      // Render category items when loaded successfully
      categoryItems = categories.map(category => (
       <Link className='footercats' to={`/Category/${category.title_en}`}>
        <p>{category.title_en}</p>
        </Link>
        
      ));
    }
  return (
    <div>
       <footer ref={ref}>
        <div className='footer'>
          <div className='footer-links'>
           <div className='footer-categories'>
            <h2>Shop</h2>
            {categoryItems}
           </div>
           <div className='footer-categories'>
            <h2>{t('info')}</h2>
<div className='infoFooter'>
            <Link  className='infoP' to='/'>{t('Home')}</Link>
              <Link  className='infoP' to='/about'>{t('About Us')}</Link>
              <Link  className='infoP' to='/contact'>{t('Contact Us')}</Link></div>
              <LanguageSwitcher />
           </div>
  
           </div>


           <div className='social-footer'>
            <img alt='faceBook'
            src='/images/FB.png' className='social-icons'/>
            <img
            alt='instagram'
             src='/images/IG.png' className='social-icons'/>
            <img alt='X'
             src='/images/X.png' className='social-icons'/>
            <img alt='Youtube'
             src='/images/YT.png' className='social-icons'/>

           </div>
          </div>
          <div className='copyright'> 
          <img alt='Tarsimage'
             src='/images/tarsimajfull.png' className='tarsimage'/>
          © 2024 all rights reserved

          </div>
          
      </footer>
    </div>
  );
});

export default Footer;