export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_UPDATE_ITEM_QTY = 'CART_UPDATE_ITEM_QTY'

export const RESET_CART = 'CART_UPDATE_ITEM_QTY'

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL'

export const LIST_ORDER_REQUEST = 'LIST_ORDER_REQUEST'
export const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS'
export const LIST_ORDER_FAIL = 'LIST_ORDER_FAIL'

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL'

export const FINALIZE_ORDER_REQUEST = 'FINALIZE_ORDER_REQUEST'
export const FINALIZE_ORDER_SUCCESS = 'FINALIZE_ORDER_SUCCESS'
export const FINALIZE_ORDER_FAIL = 'FINALIZE_ORDER_FAIL'

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL'

export const COUPON_CODE_REQUEST = 'COUPON_CODE_REQUEST'
export const COUPON_CODE_SUCCESS = 'COUPON_CODE_SUCCESS'
export const COUPON_CODE_FAIL = 'COUPON_CODE_FAIL'

export const COUPON_CHECK_REQUEST = 'COUPON_CHECK_REQUEST'
export const COUPON_CHECK_SUCCESS = 'COUPON_CHECK_SUCCESS'
export const COUPON_CHECK_FAIL = 'COUPON_CHECK_FAIL'

