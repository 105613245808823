// src/pages/SearchResults.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../Actions/productActions';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Product from '../components/Product';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import './productList.css';

function SearchResults() {
  const dispatch = useDispatch();
  const [filterCount, setFilterCount] = useState(0);
  const { error, loading, products } = useSelector((state) => state.productList);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('query') || '';

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  useEffect(() => {
    const filteredProducts = products.filter((product) =>
      product.name && product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilterCount(filteredProducts.length);
  }, [products, searchQuery]);

  let productListItems;

  if (loading) {
    productListItems = <p className='error'>Loading...</p>;
  } else if (error) {
    productListItems = <p className='error'>Error: {error}</p>;
  } else {
    const filteredProducts = products.filter((product) =>
      product.title_en && product.title_en.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (filteredProducts.length === 0) {
      productListItems = <p className='error'>No products available for this selection</p>;
    } else {
      productListItems = filteredProducts.map((product) => (
        <div key={product.id} className='card'>
          <Product product={product} />
        </div>
      ));
    }
  }

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className='products'>
        <div className='title-filter'>
          <h2 className='cat-title'>Search Results</h2>
        </div>
        <div className='card-row'>{productListItems}</div>
      </div>
      <Footer />
    </div>
  );
}

export default SearchResults;
