import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Footer from '../components/Footer';
import { ForgotPassword } from '../Actions/userActions';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import '../App.css';
import './forgetPassword.css';

function ForgetPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (!email.trim()) {
      errors.email = 'This field is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.emailFormat = 'Invalid email address';
    }
    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm?',
      message: 'Are you sure you want to reset your password?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (validateForm()) {
              setLoading(true);
              dispatch(ForgotPassword(email))
                .then(() => {
                  setMessage("If you're signed up, a new password has been sent to your email. Please check your inbox.");
                  setShowMessage(true);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const closeMessageHandler = () => {
    setShowMessage(false);
  };

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className='forgetPanel'>
        <div className='forgetLeft'>
          <h1>FORGOT YOUR PASSWORD?</h1>
        </div>
        <div className='forgetRight'>
          
          <form className='forget-container' onSubmit={submitHandler}>
          {showMessage && (
              <div className='successMessageForg'>
                <p>{message}</p>
                <button className='closeMessageBtn' onClick={closeMessageHandler}>X</button>
              </div>
            )}
            <p>E-mail address</p>
            <input
              placeholder='Enter your email'
              className='forgetinput'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className='fieldError'>{errors.email}</p>}
            {errors.emailFormat && <p className='fieldError'>{errors.emailFormat}</p>}
            <button className='forgetBtn' type='submit' disabled={loading}>
              {loading ? 'Processing...' : 'Send a new one'}
            </button>
   
            <p>We can send you a new one, just type your email.</p>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgetPassword;
