import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion as m } from 'framer-motion';
import { userAddressAdd } from '../Actions/userActions';

const loginoverlayVariants = {
  open: { opacity: 1, display: 'flex' },
  closed: { opacity: 0, transitionEnd: { display: 'none' } },
};

const getLoginVariants = () => {
  if (window.innerWidth >= 600) {
    return {
      open: { left:  '50%'},
      closed: { left: '100%' },
    };
  } else {
    return {
      open: { left: '0%' },
      closed: { left: '100%' },
    };
  }
};

const AddAddress = ({ isOpen, toggleAdd }) => {
  const dispatch = useDispatch();

  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [zip_posteal_code, setZip] = useState('');
  const [address, setAddress] = useState('');
  const [title_address, setTitleAdress] = useState('');
  const { userInfo } = useSelector(state => state.userLogin); // Get user info including token
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const [errors, setErrors] = useState({});


  const [loginVariants, setLoginVariants] = useState(getLoginVariants());

  useEffect(() => {
    const handleResize = () => {
      setLoginVariants(getLoginVariants());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const validateForm = () => {
    const errors = {};

    setErrors(errors);
    if (!phone.trim()) {
        errors.phone = 'This field is required';
    }
    if (!last_name.trim()) {
      errors.last_name = 'This field is required';
  }

    if (!first_name.trim()) {
        errors.first_name = 'This field is required';
    }

    if (!address.trim()) {
      errors.address = 'This field is required';
  }
  if (!city.trim()) {
    errors.city = 'This field is required';
  }
  if (!country.trim()) {
    errors.country = 'This field is required';
  }
  if (!zip_posteal_code.trim()) {
    errors.zip_posteal_code = 'This field is required';
  }
  if (!title_address.trim()) {
    errors.title_address = 'This field is required';
  }
      return Object.keys(errors).length === 0;
  };

  const submitHandler = (e) => {
    e.preventDefault();
   if (validateForm()) {
      dispatch(userAddressAdd( token, first_name, last_name, phone, country, city,  zip_posteal_code, address, title_address));
      toggleAdd();}

  };


  const handleToggleAdd = () => {

    toggleAdd();
  };

  return (
    <m.div
      animate={isOpen ? 'open' : 'closed'}
      variants={loginoverlayVariants}
      transition={{ duration: 0.75 }}
      className={`overlaylog ${isOpen ? 'login-overlay' : ''}`}
    >
      <m.div
        animate={isOpen ? 'open' : 'closed'}
        variants={loginVariants}
        transition={{ duration: 0.75 }}
        className="menu1"
      >
        <div className="addPanel">
          <p onClick={handleToggleAdd} className='close'>X close</p>
          <form 
          onSubmit={submitHandler}
          className='addForm'>
          <div className='addNames'>
        <div className='profRow'>
        <p>First Name</p>

      <input
      placeholder={errors.first_name}
      onChange={(e)=>setFirst_name(e.target.value)} /></div>
      <div className='profRow'>
      <p>Last Name</p>

      <input 
      placeholder={errors.last_name}
        onChange={(e)=>setLast_name(e.target.value)} />
        </div></div>

          <div className='profRowFull'>
            <div>
            <p>Phone</p>
          <input
          placeholder={errors.phone}
          type='phone'
          onChange={(e)=>setPhone(e.target.value)}
              className='addInputFull' />
          <p>Country</p>
          <select
          
          onChange={(e)=>setCountry(e.target.value)}

          className='addInputFull'
           >
        <option value="">{errors.country ? errors.country : 'select country'}</option>
        <option>UAE</option>
            <option>QATAR</option>
            <option>OMAN</option>
           </select>
           <p>City</p>
          <input
          placeholder={errors.city} 
          onChange={(e)=>setCity(e.target.value)}
          className='addInputFull' />
          <p>Zip/Postal code</p>
          <input
          placeholder={errors.zip_posteal_code}
          onChange={(e)=>setZip(e.target.value)}
          className='addInput'
          />
          <p>Address title</p>
          <input
          placeholder={errors.title_address}
          onChange={(e)=>setTitleAdress(e.target.value)}
              className='addInputFull' />
          <p>Address</p>
          <textarea
          placeholder={errors.address}
          onChange={(e)=>setAddress(e.target.value)}
         className='addField' />
         
          </div>
          </div>
          <button 
      type="submit" className='addBtn'>Save address</button>

          </form>
        </div>
      </m.div>
    </m.div>
  );
};

export default AddAddress;
