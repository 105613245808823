import Navbar from '../components/NavbarHome'
import Popup from '../components/Popup'
import Snappy from '../components/Snappy'
import '../App.css'



function Home() {

  
  return (

    <div><div className='nav-pop'>
    <Popup />
         <Navbar />
   
 </div>
 <div className='snap'>
 <Snappy /></div>




</div>
    
  );
}

export default Home;
