import React, { useState, useEffect } from 'react';
import './filter.css';
import { motion as m } from 'framer-motion';

const loginoverlayVariants = {
  open: { opacity: 1, display: 'flex' },
  closed: { opacity: 0, transitionEnd: { display: 'none' } },
};

const getLoginVariants = () => {
  if (window.innerWidth >= 600) {
    return {
      open: { left: 'calc(50% )' },
      closed: { left: '100%' },
    };
  } else {
    return {
      open: { left: '0%' },
      closed: { left: '100%' },
    };
  }
};

function FilterOverlay({ isOpen, colorOptions, sizeOptions, genderOptions, selectedColors, selectedSizes, selectedGender, handleColorChange, handleSizeChange, handleGenderChange, toggleFilterOverlay, applyFilters }) {
  const [loginVariants, setLoginVariants] = useState(getLoginVariants());

  useEffect(() => {
    const handleResize = () => {
      setLoginVariants(getLoginVariants());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <m.div
      animate={isOpen ? 'open' : 'closed'}
      variants={loginoverlayVariants}
      transition={{ duration: 0.75 }}
      className='overlayfilter'>
      <m.div
        animate={isOpen ? 'open' : 'closed'}
        variants={loginVariants}
        transition={{ duration: 0.75 }}
        className="filter-overlay">
        <div className="filter-content">
          <p onClick={toggleFilterOverlay}>X close</p>
          
          {/* Color filter */}
          <div className="filter-option">
            <h3>Color:</h3>
            <div className="color-options">
              {colorOptions.map(color => (
                <div key={color.color} className="color-option-container">
                  <div
                    className={`color-option ${selectedColors.includes(color.color) ? 'selected' : ''}`}
                    style={{ backgroundColor: color.color }}
                    onClick={() => handleColorChange(color.color)}
                  ></div>
                  <span onClick={() => handleColorChange(color.color)} className="color-title">{color.title}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Size filter */}
          <div className="filter-option">
            <h3>Size:</h3>
            <div className="size-options">
              {sizeOptions.map(size => (
                <div
                  key={size}
                  className={`size-option ${selectedSizes.includes(size) ? 'selected' : ''}`}
                  onClick={() => handleSizeChange(size)}
                >
                  {size}
                </div>
              ))}
            </div>
          </div>

          {/* Gender filter */}
          <div className="filter-option">
            <h3>Gender:</h3>
            <div className="gender-options">
              {genderOptions.map(gender => (
                <label key={gender} className="gender-option">
                  <input
                    type="radio"
                    name="gender"
                    value={gender}
                    checked={selectedGender === gender}
                    onChange={() => handleGenderChange(gender)}
                  />
                  {gender}
                </label>
              ))}
            </div>
          </div>

          {/* Submit button */}
          <button onClick={applyFilters} className="apply-filters-btn">
            Apply Filters
          </button>
        </div>
      </m.div>
    </m.div>
  );
}

export default FilterOverlay;
