// cartReducer.js

import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_ITEM_QTY,

    ADD_ORDER_SUCCESS,
    ADD_ORDER_REQUEST,
    ADD_ORDER_FAIL,

    RESET_CART,

    LIST_ORDER_SUCCESS,
    LIST_ORDER_REQUEST,
    LIST_ORDER_FAIL,

    COUPON_CODE_SUCCESS,
    COUPON_CODE_REQUEST,
    COUPON_CODE_FAIL,
} from '../constants/cartConstants';

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {

        case CART_ADD_ITEM:
            const newItem = action.payload;
            const existingItemIndex = state.cartItems.findIndex(x => x.sku === newItem.sku);
            if (existingItemIndex !== -1) {
                // If item with the same SKU exists, update the quantity of that item
                const updatedCartItems = state.cartItems.map((item, index) => {
                    if (index === existingItemIndex) {
                        return {
                            ...item,
                            qty: item.qty + newItem.qty
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    cartItems: updatedCartItems
                };
            } else {
                // If item with a different SKU, add it to the cart
                return {
                    ...state,
                    cartItems: [...state.cartItems, newItem]
                };
            }
            case CART_REMOVE_ITEM:
                return {
                  ...state,
                  cartItems: state.cartItems.filter(x => x.sku !== action.payload)
                };
 
             case CART_UPDATE_ITEM_QTY:
                    const { sku, qty } = action.payload;
                    const updatedCartItems = state.cartItems.map(item =>
                        item.sku === sku ? { ...item, qty } : item
                    );
                    
                    return {
                        ...state,
                        cartItems: updatedCartItems
                    };
            case RESET_CART:
                        return {
                          ...state,
                          cartItems: [] // Reset cartItems to empty array
                          // Reset other state properties as needed
                        };   
        default:
            return state;
    }
};
export const addOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_ORDER_REQUEST:
            return { loading: true };
        case ADD_ORDER_SUCCESS:
            return { loading: false, success: true, order: action.payload };
        case ADD_ORDER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const listOrderReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case LIST_ORDER_REQUEST:
            return { loading: true };
        case LIST_ORDER_SUCCESS:
            return { loading: false, success: true, orders: action.payload };
        case LIST_ORDER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const couponCodeReducer = (state = { coupon: [] }, action) => {
    switch (action.type) {
      case COUPON_CODE_REQUEST:
        return { loading: true };
      case COUPON_CODE_SUCCESS:
        return { loading: false, success: true, orders: action.payload };
      case COUPON_CODE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };