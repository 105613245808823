import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    RESET_LOGIN_ERROR,

    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,

    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,

    USER_ADDRESS_LIST_REQUEST,
    USER_ADDRESS_LIST_SUCCESS,
    USER_ADDRESS_LIST_FAIL,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,

    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,

    CHANGE_PASSWORD_CLEAR_SUCCESS,
    CHANGE_EMAIL_CLEAR_SUCCESS,

    CHANGE_EMAIL_REQUEST,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_EMAIL_FAIL,

    
    CONTACT_FORM_REQUEST,
    CONTACT_FORM_SUCCESS,
    CONTACT_FORM_FAIL,
    RESET_CONTACT_FORM,

    REQUEST_NEW_ACTIVATION_LINK, 
    REQUEST_NEW_ACTIVATION_LINK_SUCCESS, 
    REQUEST_NEW_ACTIVATION_LINK_FAIL ,

} from '../constants/userConstants'

const initialState = {
    loading: false,
    error: null,
    success: null, // Initialize success state
};

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
            case GOOGLE_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case GOOGLE_LOGIN_FAIL:
      return { loading: false, error: action.payload };

       case RESET_LOGIN_ERROR:
             return { ...state, error: null }; 

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}



export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, userRegisterInfo: action.payload }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}
export const userDetailsReducer = (state = {user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return {...state, loading: true }

        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload }

        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }



        default:
            return state
    }
}
export const userUpdateReducer = (state = {user: {} }, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return {...state, loading: true }

        case USER_UPDATE_SUCCESS:
            return { loading: false, user: action.payload }

        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload }



        default:
            return state
    }
}
export const userAddressListReducer = (state = { }, action) => {
    switch (action.type) {
        case USER_ADDRESS_LIST_REQUEST:
            return {...state, loading: true }

        case USER_ADDRESS_LIST_SUCCESS:
            return { loading: false, addresses: action.payload }

        case USER_ADDRESS_LIST_FAIL:
            return { loading: false, error: action.payload }



        default:
            return state
    }
}
export const forgotPasswordReducer = (state = {user: {} }, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {...state, loading: true }

        case FORGOT_PASSWORD_SUCCESS:
            return { loading: false, user: action.payload }

        case FORGOT_PASSWORD_FAIL:
            return { loading: false, error: action.payload }



        default:
            return state
    }
}

export const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, loading: true };

        case CHANGE_PASSWORD_SUCCESS:
            return { loading: false, success: action.payload }; // Set success message

        case CHANGE_PASSWORD_FAIL:
            return { loading: false, error: action.payload, success: null };

        case CHANGE_PASSWORD_CLEAR_SUCCESS:
            return { ...state, success: null };

        default:
            return state;
    }
};

export const changeEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_EMAIL_REQUEST:
            return { ...state, loading: true };

        case CHANGE_EMAIL_SUCCESS:
            return { loading: false, success: action.payload }; // Set success message

        case CHANGE_EMAIL_FAIL:
            return { loading: false, error: action.payload, success: null };

        case CHANGE_EMAIL_CLEAR_SUCCESS:
                return { ...state, success: null };

        default:
            return state;
    }
};
export const contactFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_FORM_REQUEST:
      return { loading: true };
    case CONTACT_FORM_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CONTACT_FORM_FAIL:
      return { loading: false, error: action.payload };
      case RESET_CONTACT_FORM:
        return {
          ...initialState,
        };
    default:
      return state;
  }
};

export const activationLinkReducer = (state = initialState, action) => {
    switch (action.type) {
      case REQUEST_NEW_ACTIVATION_LINK:
        return { ...state, loading: true, success: false, error: null };
      case REQUEST_NEW_ACTIVATION_LINK_SUCCESS:
        return { ...state, loading: false, success: true, error: null };
      case REQUEST_NEW_ACTIVATION_LINK_FAIL:
        return { ...state, loading: false, success: false, error: action.payload };
      default:
        return state;
    }
  };