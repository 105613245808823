import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userUpdate } from '../Actions/userActions';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the default CSS for the confirm alert
import { confirmAlert } from 'react-confirm-alert';


const countryList = [
  { code: '----', name: '----' },
  { code: 'AE', name: 'UAE' },
  { code: 'QA', name: 'Qatar' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'OM', name: 'Oman' },
  // Add more countries as needed
];

function ProfileInfo() {
  // Access user information from Redux store
  const userDets = useSelector(state => state.userDetails);
  const { user } = userDets;
  const defaultName = user && user.data ? user.data.user.first_name : '';
  const defaultLastName = user && user.data ? user.data.user.last_name : '';
  const defaultPhone = user && user.data ? user.data.user.phone : '';
  const defaultBirthDate = user && user.data ? user.data.user.birth_date : '';
  const defaultGender = user && user.data ? user.data.user.gender : '';
  const defaultCountry = user && user.data ? user.data.user.country : '';

  const [first_name, setFirst_name] = useState(defaultName);
  const [last_name, setLast_name] = useState(defaultLastName);
  const [phone, setPhone] = useState(defaultPhone);
  const [country, setCountry] = useState(defaultCountry);
  const [gender, setGender] = useState(defaultGender);
  const { userInfo } = useSelector(state => state.userLogin); // Get user info including token
  const token = userInfo && userInfo.data ? userInfo.data.token : null;

  // Split default birth date into day, month, year
  const defaultBirthDateParts = defaultBirthDate ? defaultBirthDate.split('-') : ['', '', ''];
  const [birthDay, setBirthDay] = useState(defaultBirthDateParts[2]);
  const [birthMonth, setBirthMonth] = useState(defaultBirthDateParts[1]);
  const [birthYear, setBirthYear] = useState(defaultBirthDateParts[0]);

  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  const dispatch = useDispatch();

  // Generate options for day, month, year
  const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  const years = Array.from({ length: 103 }, (_, i) => (1920 + i).toString());

  const submitHandler = (e) => {
    e.preventDefault();
    if (!first_name || !last_name) {
      setErrorMessage('First Name and Last Name are required.');
      return;
    }

    let birth_date = '';
    if (birthDay && birthMonth && birthYear) {
      birth_date = `${birthYear}-${birthMonth}-${birthDay}`;
    }

    confirmAlert({
      title: 'Confirm?',
      message: 'Are you sure you want to update your profile information?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(userUpdate(token, first_name, last_name, phone, gender, birth_date, country, () => {
              setSuccessMessage('User info updated successfully.');
              setTimeout(() => setSuccessMessage(''), 4000); // Clear message after 10 seconds
            }));
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };


  return (
    <div className='profCont'>
      <div className='profInfo'>
        <h4>My Profile</h4>
        <form className='profForm' onSubmit={submitHandler}>
          <div>
            <div className='profRow'>
              <p>First Name</p>
              <input
                value={first_name}
                className='editInput'
                onChange={(e) => setFirst_name(e.target.value)}
                required
              />
            </div>
            <div className='profRow'>
              <p>Last Name</p>
              <input
                value={last_name}
                className='editInput'
                onChange={(e) => setLast_name(e.target.value)}
                required
              />
            </div>
          </div>
          <div>
            <div className='profRow'>
              <p>Phone</p>
              <input
                value={phone}
                className='editInput'
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Optional"
              />
            </div>
            <div className='profRow'>
              <p>Gender</p>
              <select
                value={gender}
                className='editInput'
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="----">----</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
          <div>
            <div className='profRow'>
              <p>Birth Date</p>
              <div className='birthDateSelects'>
                <select
                  className='editInput'
                  value={birthDay}
                  onChange={(e) => setBirthDay(e.target.value)}
                >
                  <option value="">Day</option>
                  {days.map(day => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <select
                  className='editInput'
                  value={birthMonth}
                  onChange={(e) => setBirthMonth(e.target.value)}
                >
                  <option value="">Month</option>
                  {months.map(month => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                <select
                  className='editInput'
                  value={birthYear}
                  onChange={(e) => setBirthYear(e.target.value)}
                >
                  <option value="">Year</option>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='profRow'>
              <p>Country</p>
              <select
                value={country}
                className='editInput'
                onChange={(e) => setCountry(e.target.value)}
              >
                {countryList.map(country => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='profCheck'>
            <label htmlFor="consent">
              <input type="checkbox" id="consent" name="consent" />
              I approve to receive commercial electronic mail to my contact info from Parvari Fashion and the data I have provided above can be used by US in line with this purpose within the scope of Acknowledgement Letter Regarding The Protection of Personal Data.
            </label>
          </div>
          <button type="submit" className='updateBtn'>Update</button>

        </form>

      </div>
      {successMessage && <div className='successMessage'>{successMessage}</div>}
      {errorMessage && <div className='errorMessage'>{errorMessage}</div>}
    </div>
  );
}

export default ProfileInfo;
