import React from 'react';
import { InView } from 'react-intersection-observer';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Footer from '../components/Footer';
import ShareExperience from '../components/ShareExperience';
import './About.css';

function About() {
  return (
    <div>
      <div className="about-container">
        <div className="nav-pop">
          <Popup />
          <Navbar />
        </div>
<div className='about_details'>
        <InView rootMargin="-10px 0px">
          {({ inView, ref }) => (
            <div ref={ref} className={`about-content ${inView ? 'animate' : ''}`}>
              <div className="about-text">
                <h2>About Us</h2>
                <p>
                  At Parvari Fashion, we believe that comfort and style should go
                  hand in hand, especially when it comes to your yoga practice.
                </p>
              </div>
              <div 
                className="about-image"
                style={{ backgroundImage: "url('/images/about.jpg')" }}
              />
            </div>
          )}
        </InView>

        <InView rootMargin="-10px 0px">
          {({ inView, ref }) => (
            <div ref={ref} className={`about-content ${inView ? 'animate' : ''}`}>
              <div 
                className="about-image"
                style={{ backgroundImage: "url('/images/about2.webp')" }}
              />
              <div className="about-text">
                <p>
                  Our mission is to provide you with a carefully curated collection of
                  yoga tops, leggings, and sports bras that not only look great but
                  also enhance your performance on the mat.
                </p>
              </div>
            </div>
          )}
        </InView>

        <InView rootMargin="-10px 0px">
          {({ inView, ref }) => (
            <div ref={ref} className={`about-content ${inView ? 'animate' : ''}`}>
              <div className="about-text">
                <p>
                  We understand that every body is unique, which is why we offer a range of sizes and styles to
                  suit your individual needs. Whether you’re a beginner or an
                  experienced yogi, our products are designed to support you in your
                  journey to wellness and self-discovery.
                </p>
              </div>
              <div 
                className="about-image"
                style={{ backgroundImage: "url('/images/about3.webp')" }}
              />
            </div>
          )}
        </InView>

        <ShareExperience /> 
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;