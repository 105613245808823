import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../Actions/productActions';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Product from '../components/Product';
import FilterOverlay from '../components/FilterOverlay';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import './productList.css';

function ProductList() {
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { error, loading, products } = useSelector(state => state.productList);
  const { title } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(title || 'All');
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [filterCount, setFilterCount] = useState(0); 
  const [tempColors, setTempColors] = useState([]);
  const [tempSizes, setTempSizes] = useState([]);
  const [tempGender, setTempGender] = useState('');
  let colorOptions = [];
  let sizeOptions = [];
  let genderOptions = ['male', 'female', 'unisex',]; // Ensure the gender options are properly populated

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);
  
  useEffect(() => {
    setSelectedCategory(title || 'All');
    setSelectedColors([]);
    setSelectedSizes([]);
    setSelectedGender('');
  }, [title]);

  const handleColorChange = (color) => {
    setTempColors(prevColors => prevColors.includes(color) ? prevColors.filter(c => c !== color) : [...prevColors, color]);
  };

  const handleSizeChange = (size) => {
    setTempSizes(prevSizes => prevSizes.includes(size) ? prevSizes.filter(s => s !== size) : [...prevSizes, size]);
  };

  const handleGenderChange = (gender) => {
    setTempGender(gender);
  };

  const toggleFilterOverlay = () => setIsFilterOpen((prev) => !prev);

  const applyFilters = () => {
    setSelectedColors(tempColors);
    setSelectedSizes(tempSizes);
    setSelectedGender(tempGender);
    setIsFilterOpen(false);
  };

  useEffect(() => {
    const filteredProducts = products.filter(product => {
      const categoryMatch = selectedCategory === 'All' || product.category.some(category => category.title_en.toLowerCase() === selectedCategory.toLowerCase());
      const colorMatch = selectedColors.length === 0 || selectedColors.some(color => product.color.some(pColor => pColor.color === color));
      const sizeMatch = selectedSizes.length === 0 || selectedSizes.some(size => product.size.some(pSize => pSize.options === size));
      const genderMatch = selectedGender === '' || selectedGender === product.gender.toLowerCase();
      return categoryMatch && colorMatch && sizeMatch && genderMatch;
    });
    setFilterCount(filteredProducts.length);
  }, [products, selectedCategory, selectedColors, selectedSizes, selectedGender]);

  let productListItems;

  if (loading) {
    productListItems = <p className='error'>Loading...</p>;
  } else if (error) {
    productListItems = <p className='error'>Error: {error}</p>;
  } else {
    products.forEach(product => {
      product.color.forEach(color => {
        if (!colorOptions.find(opt => opt.color === color.color)) {
          colorOptions.push({
            color: color.color,
            title: color.title_en
          });
        }
      });
      product.size.forEach(size => {
        if (!sizeOptions.includes(size.options)) {
          sizeOptions.push(size.options);
        }
      });
      // Gender options are already initialized to ['male', 'female']
    });

    let filteredProducts = products.filter(product => {
      const categoryMatch = selectedCategory === 'All' || product.category.some(category => category.title_en.toLowerCase() === selectedCategory.toLowerCase());
      const colorMatch = selectedColors.length === 0 || selectedColors.some(color => product.color.some(pColor => pColor.color === color));
      const sizeMatch = selectedSizes.length === 0 || selectedSizes.some(size => product.size.some(pSize => pSize.options === size));
      const genderMatch = selectedGender === '' || selectedGender === product.gender.toLowerCase();
      return categoryMatch && colorMatch && sizeMatch && genderMatch;
    });

    if (filteredProducts.length === 0) {
      productListItems = <p className='error'>No products available for this selection</p>;
    } else {
      productListItems = filteredProducts.map(product => (
        <div key={product.id} className="card">
          <Product product={product} />
        </div>
      ));
    }
  }

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      
      <div className='products'>
        <div className='title-filter'>
          <h2 className='cat-title'>{selectedCategory}</h2>
          <div className="filter-options" onClick={toggleFilterOverlay}>
            {filterCount > -1 && <span>{filterCount} Results</span>}
            <img className='filterBtn' src="/images/filter.png" alt="filter button" />
          </div>
        </div>
        {setIsFilterOpen && (
          <FilterOverlay
            colorOptions={colorOptions}
            sizeOptions={sizeOptions}
            genderOptions={genderOptions}
            selectedColors={tempColors}
            selectedSizes={tempSizes}
            selectedGender={tempGender}
            handleColorChange={handleColorChange}
            handleSizeChange={handleSizeChange}
            handleGenderChange={handleGenderChange}
            toggleFilterOverlay={toggleFilterOverlay}
            isOpen={isFilterOpen}
            applyFilters={applyFilters}
          />
        )}
        <div className="card-row">
          {productListItems}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProductList;
