import React from 'react';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Popup from '../components/Popup';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import Product from '../components/Product';
import './campaign.css';

function Campaign() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';
  const { id: campaignId } = useParams();
  const { campaigns } = useSelector((state) => state.CampaignList);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;


  const campaign = campaigns.campaign
    ? campaigns.campaign.find((c) => c.id.toString() === campaignId)
    : null;

  const campMedia = campaign ? campaign.campaign_media : [];

  return (
    <div>
      <div className="nav-pop">
        <Popup />
        <Navbar />
      </div>
      
      <div className="campaign-details">
        {campaign ? (
          <div>
            <InView rootMargin="-100px 0px">
              {({ inView, ref }) => (
                <div ref={ref} className={`campaign-content-main ${inView ? 'animate' : ''}`}>
                  <div 
                    className={`camp-image ${inView ? 'animate' : ''}`}
                    style={{background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%), url(${API_BASE_URL}${campaign.image}) center `}}    
                  >
                    <div ref={ref} className={`camp-text-main ${inView ? 'animate' : ''}`}>
                      <h1>{currentLanguage === 'ar' ? campaign.title_ar : campaign.title_en}</h1>
                      <p>{currentLanguage === 'ar' ? campaign.description_ar : campaign.description_en}</p>
                    </div>
                    <button className='campBtn'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 50 50" fill="none">
                        <path d="M25 41.6666L37.5 29.1666M25 41.6666L12.5 29.1666M25 41.6666V19.7916M25 8.33331V13.5416" stroke="#fafafa" strokeWidth="3.125" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </InView>
            {campMedia.length > 0 ? (
              campMedia.map((media) => (
                <InView key={media.id} rootMargin="-10px 0px">
                  {({ inView, ref }) => (
                    <>
                      {media.style === 'Vertical' && (
                        <div ref={ref} className={`campaign-content-vertical ${inView ? 'animate' : ''}`}>
                          <div 
                                     className="campaign-image"
                            style={{background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%), url(${API_BASE_URL}${media.image})`}}
                          >
                     <div ref={ref} className={`camp-text-main ${inView ? 'animate' : ''}`}>
                            <h1>{currentLanguage === 'ar' ? media.title_ar : media.title_en}</h1>
                            <p>{currentLanguage === 'ar' ? media.description_ar : media.description_en}</p>
                                                      <button className='campvertBtn'>{currentLanguage === 'ar' ? media.title_ar : media.title_en}</button>

                          </div>
                          </div>

                        </div>
                      )}
                      {media.style === 'Horizontal' && (
                        <div ref={ref} className={`campaign-content-horizontal ${inView ? 'animate' : ''}`}>
                          <div 
                                     className="campaign-image"
                            style={{background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%), url(${API_BASE_URL}${media.image})`}}
                          >         
                          <div className={`camp-text-main ${inView ? 'animate' : ''}`}>                 
                            <h1>{currentLanguage === 'ar' ? media.title_ar : media.title_en}</h1>
                            <p>{currentLanguage === 'ar' ? media.description_ar : media.description_en}</p>
                            </div> 
                       </div>
                        </div>
                      )}
                      {media.style === 'Left' && (
                        <div ref={ref} className={`campaign-content-left ${inView ? 'animate' : ''}`}>
                          <div 
                            className="campaign-image"
                            style={{background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%), url(${API_BASE_URL}${media.image})`}}
                          />
                          <div className="camp-text">
                            <h2>{currentLanguage === 'ar' ? media.title_ar : media.title_en}</h2>
                            <p>{currentLanguage === 'ar' ? media.description_ar : media.description_en}</p>
                          </div>
                        </div>
                      )}
                      {media.style === 'Right' && (
                        <div ref={ref} className={`campaign-content-right ${inView ? 'animate' : ''}`}>
                          <div 
                              className="campaign-image"
                            style={{background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%), url(${API_BASE_URL}${media.image})`}}
                          />
                          <div className="camp-text">
                            <h2>{currentLanguage === 'ar' ? media.title_ar : media.title_en}</h2>
                            <p>{currentLanguage === 'ar' ? media.description_ar : media.description_en}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </InView>
              ))
            ) : (
              <p></p>
            )}
  {campaign.products && campaign.products.length > 0 && (
<h3 style={{textAlign:"center"}}>{t('Products')}</h3>)}
            <div className='similar-products-list'>

              {campaign.products && campaign.products.length > 0 ? (
                campaign.products.map(product => (
                  <div key={product.id} className='cardSim'>
                    <Product product={product} />
                  </div>
                ))
              ) : (
                ''
              )}
            </div>
            </div>
        ) : (
          <p>Page Not Found</p>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Campaign;
