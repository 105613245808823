import React, { useEffect } from 'react';
import Footer from "../components/Footer";
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { requestNewActivationLink } from '../Actions/userActions';

function ActivationFailed() {
  const location = useLocation();
  const { search } = location;
  const userId = new URLSearchParams(search).get('user_id');
  const dispatch = useDispatch();

  const activationLinkRequest = useSelector((state) => state.activationLinkRequest);
  const { loading, success, error } = activationLinkRequest;

  const handleRequestNewLink = () => {
    dispatch(requestNewActivationLink(userId));
  };

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className='orderCancel'>
        <h2>Verification Failed</h2>
        <p>Expired or wrong verification link</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 115 115" fill="none">
          <path d="M57.5002 9.58331C31.0022 9.58331 9.5835 31.0021 9.5835 57.5C9.5835 83.9979 31.0022 105.417 57.5002 105.417C83.9981 105.417 105.417 83.9979 105.417 57.5C105.417 31.0021 83.9981 9.58331 57.5002 9.58331ZM81.4585 74.7021L74.7022 81.4583L57.5002 64.2562L40.2981 81.4583L33.5418 74.7021L50.7439 57.5L33.5418 40.2979L40.2981 33.5416L57.5002 50.7437L74.7022 33.5416L81.4585 40.2979L64.2564 57.5L81.4585 74.7021Z" fill="#F24E1E"/>
        </svg>
        <button className='trackBtnCancel' onClick={handleRequestNewLink} disabled={loading}>
          {loading ? 'Sending...' : 'Send new link'}
        </button>
        {success && <p>A new activation link has been sent to your email.</p>}
        {error && <p>Error: {error.message}</p>}
      </div>
      <Footer />
    </div>
  );
}

export default ActivationFailed;
