import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './sizeSelector.css';

const SizeSelector = ({ onSizeChange }) => {
  const productDetails = useSelector(state => state.productDetails);
  const { loading, error, product } = productDetails || {};
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(0);
  const [unit, setUnit] = useState('cm'); // 'cm' or 'inches'
  const sliderRef = useRef(null);

  useEffect(() => {
    if (product && product.size && product.measurement) {
      const mappedSizes = product.size.map(size => {
        const measurements = product.measurement
          .filter(meas => meas.size.some(s => s.id === size.id))
          .map(meas => ({
            title: meas.title_en,
            value: meas.size_measurement
          }));
        return {
          ...size,
          measurements
        };
      });
      setSizes(mappedSizes);
    }
  }, [productDetails]);

  const handleThumbMove = (event) => {
    const slider = sliderRef.current;
    if (!slider) return; // Ensure the slider element exists
    const rect = slider.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const newSelectedSize = Math.round((offsetX / rect.width) * (sizes.length - 1));
    setSelectedSize(Math.max(0, Math.min(newSelectedSize, sizes.length - 1))); // Ensure selectedSize stays within bounds
    onSizeChange(newSelectedSize);
  };

  const handleSizeClick = (index) => {
    setSelectedSize(index);
    onSizeChange(index);
  };

  const convertToInches = (cm) => (cm / 2.54).toFixed(2);

  if (loading) {
    return <div>Loading sizes...</div>;
  }

  if (error) {
    return <div>Error loading sizes</div>;
  }

  if (!sizes.length) {
    return <div>No sizes available</div>;
  }

  return (
    <div className="size-selector">
            <div className="unit-toggle-buttons">
        <button onClick={() => setUnit('cm')} disabled={unit === 'cm'}>CM</button>
        <button onClick={() => setUnit('inches')} disabled={unit === 'inches'}>Inches</button>
      </div>
      <div className="size-slider" ref={sliderRef} onClick={handleThumbMove}>
        <div className="slider-track" />
        {sizes.map((size, index) => (
          <div
            key={size.id}
            className="slider-mark"
            style={{ left: `${(index / (sizes.length - 1)) * 100}%` }}
            onClick={() => handleSizeClick(index)}
          >
            <div className="size-label">{size.options}</div>
          </div>
        ))}
        <div
          className="slider-thumb"
          style={{ left: `${(selectedSize / (sizes.length - 1)) * 100}%` }}
        />
      </div>
      {selectedSize !== null && ( // Add this check to avoid rendering measurements when selectedSize is null
        <div className="measurements">
          {sizes[selectedSize]?.measurements.map((meas, index) => (
            <p key={index}>
              {meas.title}: {unit === 'inches' ? `${convertToInches(meas.value)} in` : `${meas.value} cm`}
            </p>
          ))}
        </div>
      )}

    </div>
  );
};

export default SizeSelector;
