import React, { useState, useEffect } from 'react';
import { Element, Events, scrollSpy, animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import './Snappy.css'; // Import the CSS file
import Footer from './Footer';
import Categories from './Categories';
import debounce from 'lodash.debounce'; // Import debounce from lodash
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Snappy = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [lastScrollTime, setLastScrollTime] = useState(0);
  const [verticalImages, setVerticalImages] = useState([]);
  const [horizontalImages, setHorizontalImages] = useState([]);
  const [t, i18n] = useTranslation();
  const currentLanguage = i18n.language;
  

  useEffect(() => {
    Events.scrollEvent.register('begin', function() {
    });

    Events.scrollEvent.register('end', function() {
    });

    scrollSpy.update();

    window.addEventListener('wheel', debouncedHandleScroll); // Use debouncedHandleScroll
    window.addEventListener('keydown', handleKeyDown);

    fetchImages();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
      window.removeEventListener('wheel', debouncedHandleScroll); // Remove debounced function
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentSection]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/home/home_poster`);
      const images = response.data.data['home poster'];
      const verticalImages = images.filter((image) => image.title === 'vertical');
      const horizontalImages = images.filter((image) => image.title === 'horizontal');
      setVerticalImages(verticalImages);
      setHorizontalImages(horizontalImages);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleScroll = (e) => {
    const sectionHeight = window.innerHeight;
    const currentTime = new Date().getTime();

    if (currentTime - lastScrollTime < 1000) {
      return;
    }

    const delta = Math.sign(e.deltaY);
    const direction = delta > 0 ? 'down' : 'up';

    scrollToSection(direction, sectionHeight);
    setLastScrollTime(currentTime);
  };

  const debouncedHandleScroll = debounce(handleScroll, 200); // Debounce handleScroll

  const handleKeyDown = (e) => {
    const sectionHeight = window.innerHeight;

    if (e.keyCode === 38) {
      scrollToSection('up', sectionHeight);
    } else if (e.keyCode === 40) {
      scrollToSection('down', sectionHeight);
    }
  };

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';

  const scrollToSection = (direction, sectionHeight) => {
    const maxSectionIndex = verticalImages.length + horizontalImages.length + 2; // Adjusted for all sections including footer
    let targetSection = currentSection;

    if (direction === 'down' && currentSection < maxSectionIndex) {
      targetSection++;
    } else if (direction === 'up' && currentSection > 0) {
      targetSection--;
    }

    const scrollPosition = targetSection * sectionHeight;

    scroll.scrollTo(scrollPosition, {
      smooth: true,
      duration: 800,
    });

    setCurrentSection(targetSection);
  };

  return (
    <div>
      {verticalImages.map((image, index) => (
        <Element name={`section${index + 1}`} key={image.id} className={`element section${index + 1}`}>
          <div
            className="posterPic"
            style={{background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%), url(${API_BASE_URL}${image.image}) no-repeat top center`,backgroundSize:'cover'}}
            alt={image.title}
          >
            <h2 className="posterDesc">{currentLanguage === 'ar' ? image.descriptions_1_ar : image.descriptions_1_en}</h2>
            <h2 className="posterDesc1">{currentLanguage === 'ar' ? image.descriptions_2_ar : image.descriptions_2_en}</h2>
          </div>
        </Element>
      ))}

      <Element name="extraSection" className="element catElement">
        <Categories />
      </Element>
      <Element name="extraSection1" className="horizElement">
        {horizontalImages.map((image) => (
          <div
            key={image.id}
            className="posterPicHori"
            style={{ background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%),url(${API_BASE_URL}${image.image}) no-repeat top center`, backgroundSize: 'cover' }}
            alt={image.title}
          >
          <Link to={image.url} className="horizDesc1">{currentLanguage === 'ar' ? image.descriptions_2_ar : image.descriptions_2_en}</Link>
          <Link to={image.url} className="horizDesc">{currentLanguage === 'ar' ? image.descriptions_1_ar : image.descriptions_1_en}</Link>
          </div>
        ))}
      </Element>
      {horizontalImages.map((image, index) => (
        <Element name={`section${index + 1}`} key={image.id} className={`element phoneHoriz`}>
          <div
            className="posterPicHP"
            style={{ background: `radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40024509803921573) 100%),url(${API_BASE_URL}${image.image}) no-repeat top center`, backgroundSize: 'cover' }}
            alt={image.title}
          >
          <Link to={image.url} className="horizDesc1">{currentLanguage === 'ar' ? image.descriptions_2_ar : image.descriptions_2_en}</Link>
          <Link to={image.url} className="horizDesc">{currentLanguage === 'ar' ? image.descriptions_1_ar : image.descriptions_1_en}</Link>
          </div>
        </Element>
      ))}
      <Element name="footer" className="element">
        <Footer />
      </Element>
    </div>
  );
};

export default Snappy;
