import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion as m } from 'framer-motion';
import { login, googleLogin } from '../Actions/userActions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const loginoverlayVariants = {
  open: { opacity: 1, display: 'flex' },
  closed: { opacity: 0, transitionEnd: { display: 'none' } },
};

const getLoginVariants = () => {
  if (window.innerWidth >= 600) {
    return {
      open: { left: 'calc(50% )' },
      closed: { left: '100%' },
    };
  } else {
    return {
      open: { left: '0%' },
      closed: { left: '100%' },
    };
  }
};

const Login = ({ isOpen, toggleLogin }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const [loginVariants, setLoginVariants] = useState(getLoginVariants());

  useEffect(() => {
    if (userInfo) {
      setLoginError('');
    }
  }, [userInfo]);

  useEffect(() => {
    if (error) {
      setLoginError(error);
      const timer = setTimeout(() => {
        setLoginError('');
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    const storedUserInfo = document.cookie
      .split('; ')
      .find(row => row.startsWith('userInfo='))
      ?.split('=')[1];

    if (storedUserInfo && !userInfo) {
      dispatch({ type: 'USER_LOGIN_SUCCESS', payload: JSON.parse(storedUserInfo) });
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    const handleResize = () => {
      setLoginVariants(getLoginVariants());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!email.trim()) {
      errors.email = 'This field is required';
    }
    if (!password.trim()) {
      errors.password = 'This field is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(login(email, password, rememberMe));
    }
  };

  const handleToggleLogin = () => {
    setEmail('');
    setPassword('');
    toggleLogin();
  };
  const handleCredentialResponse = (response) => {
    const gIdToken = response.credential;
    dispatch(googleLogin(gIdToken)).then(() => {
      document.body.style.overflowX = 'hidden';
    }).catch(() => {
      document.body.style.overflowX = 'hidden';
    });
  };
  
  const loadGoogleScript = () => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    } else {
      initializeGoogleSignIn();
    }
  };
  
  useEffect(() => {
    loadGoogleScript();
  }, []);
  
  const initializeGoogleSignIn = () => {
    window.google.accounts.id.initialize({
      client_id: '982149822654-stn6dh2b7rmvdv8235lrlidhnq6vg68v.apps.googleusercontent.com', // Replace with your Google Client ID
      callback: handleCredentialResponse,
    });
  
    window.google.accounts.id.renderButton(
      document.getElementById('googleSignInBtn'),
      {
        width: 300,
        theme: 'outline', // You can also try different themes
        size: 'large', // Adjust the size if needed
      
      }
    );
  
    window.google.accounts.id.prompt(); // to display the One Tap prompt if the user is eligible
  };
  
  

  return (
    <m.div

      animate={isOpen ? 'open' : 'closed'}
      variants={loginoverlayVariants}
      transition={{ duration: 0.75 }}
      className={`overlaylog ${isOpen ? 'login-overlay' : ''}`}
    >
      <m.div
        animate={isOpen ? 'open' : 'closed'}
        variants={loginVariants}
        transition={{ duration: 0.75 }}
        className="menu1"
      >
        <div className="login-panel">
          <p onClick={handleToggleLogin} style={{cursor:'pointer'}} className='close'>X {t('close')}</p>
          {loginError && <div className='loginError'>{loginError}</div>}
          <form onSubmit={submitHandler}>
            <div className='login-container'>
              <p>{t('login.email')}</p>
              <input
                className="loginput"
                placeholder={errors.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p>{t('login.password')}</p>
              <input
                className="loginput"
                type="password"
                placeholder={errors.password}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='forget-cont'>
                <Link className='forgot' to='/ForgotPassword'>{t('login.forgotPassword')}</Link>
                <p>
                  <input
                    type='checkbox'
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  /> {t('login.rememberMe')}
                </p>
              </div>

              <button className='loginBtn' type="submit" disabled={loading}>
                {loading ? 'Signing In...' : t('login.signIn')}
              </button>
              <p style={{ color: '#ffffff', marginLeft: '49%' }}>or</p>

<Link style={{ width: '100%' }} to="/Register">
  <button className='registerBtn'>{t('login.register')}</button>
</Link>

                <div id="googleSignInBtn" className="customGPlusSignIn"></div>
   

            </div>
          </form>
        </div>
      </m.div>
    </m.div>
  );
};

export default Login;
