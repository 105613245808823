import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { listOrder, resetCart} from '../Actions/cartActions';
import { listProducts } from '../Actions/productActions';
import { Link } from 'react-router-dom';
import "./payment.css";
import { useTranslation } from 'react-i18next';
import Footer from "../components/Footer"
import Navbar from '../components/Navbar'
import Popup from '../components/Popup'


function PaymentSuccess() {
  const location = useLocation();
  const { search } = location;
  const orderId = new URLSearchParams(search).get('order_id');
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const { orders, loading: orderLoading, error: orderError } = useSelector((state) => state.ordersList);
  const { products, loading: productLoading, error: productError } = useSelector((state) => state.productList);
  const { t, i18n } = useTranslation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';
  


  

  useEffect(() => {
    if (token) {
      dispatch(listOrder(token));
      dispatch(listProducts());

    }
  }, [dispatch, token]);
  
  console.log('Orders:', orders);
  console.log('Products:', products);
  
  const getOrderProduct = (sku, colorId) => {
    const product = products?.find((p) =>
      p.storge.some((storage) => storage.SKU === sku)
    );

    if (product) {
      const media = product.media?.find(
        (mediaItem) => mediaItem.color.id === colorId
      );
      return {
        title: product.title_en,
        image: media ? media.image1 : null
      };
    }
    return { title: 'Product title not available', image: null };
  };

  if (orderLoading || productLoading) {
    return <div>Loading...</div>;
  }

  if (orderError) {
    return <div>Error: {orderError}</div>;
  }

  if (productError) {
    return <div>Error: {productError}</div>;
  }

    // Conditional Rendering
    if (orderLoading || productLoading) {
      return <div>Loading...</div>;
    }
  
    if (orderError) {
      return <div>Error: {orderError}</div>;
    }
  
    if (productError) {
      return <div>Error: {productError}</div>;
    }
  
    if (!orders || !products) {
      return <div>No data available</div>;
    }

  const orderDetails = orders?.data?.['order detail']?.filter(order => order.order.order_id === orderId) || [];

  if (orderDetails.length === 0) {
    return <div>Order not found</div>;
  }
  

  const orderInfo = orderDetails[0]?.order || {};
  const { shipping_address = {}, order_id = '', total = '', payment_date = '' } = orderInfo;
  

  return (
    <div>
  <div className='nav-pop'>
    <Popup />
         <Navbar />
   
 </div>


      <div className='checkout-process'>
        <h5>1.Cart summary</h5>
        <h5>2.Shipping and payment</h5>
        <h5 className='summarySelect'>3.Order confirmation</h5>
      </div>
      <h4 style={{ textAlign: 'center' }} className='phone'>Order confirmation</h4>
      <div className='orderSuccess'>
        <div className='successDetail'>


        <div style={{ paddingLeft: '10px' }}>
                    <h2>Payment confirmed</h2>
          <p> <strong>Shipped to "{shipping_address.title_address || 'N/A'}" address</strong></p>
          <div style={{ display: 'flex', gap: '50px' }}>
            <p><strong>Order ID:</strong> {orderInfo.order_id}</p>
            <p><strong>Total:</strong> {orderInfo.total}$</p>
          </div>
        </div>
        <div className='orderImages'>
          {orderDetails.slice(0, 2).map((orderDetail, index) => {
            const { SKU, colorcount } = orderDetail.storage;
            const { title, image: productImage } = getOrderProduct(SKU, colorcount.id);
            const { storage,  } = orderDetail;

            return (
              <div key={index} className="product-detail-ord">
                <div className="image-container">
                  {productImage ? (
                    <img className='orderImg' src={`${API_BASE_URL}${productImage}`} alt={title} />
                  ) : (
                    <p>{t('Product image not available')}</p>
                  )}
                  <p className="image-text">{storage.colorcount.title_en} {title}</p>
                </div>
              </div>
            );
          })}
          {orderDetails.length > 2 && (
            <div className="additional-orders-pay">
              <p>{orderDetails.length - 2}+</p>
            </div>
          )}
        <p className='payDate'>{orderInfo.payment_date}</p>

        </div>
      </div>

        <div className='successTrack'>
          <h2>Payment Successful</h2>
          <img style={{width:'25%', marginTop:'30px' }} src='/images/tik.png' />
          <Link className='trackBtn' to={`/order/${orderId}`}>Track your order
          <svg style={{marginLeft:'60px'}} xmlns="http://www.w3.org/2000/svg" width="28" height="36" viewBox="0 0 28 36" fill="none">
            <path d="M25.25 33H17.5219C18.8201 31.8408 20.0444 30.6014 21.1875 29.2891C25.4766 24.3563 27.75 19.1562 27.75 14.25C27.75 10.6033 26.3013 7.10591 23.7227 4.52728C21.1441 1.94866 17.6467 0.5 14 0.5C10.3533 0.5 6.85591 1.94866 4.27728 4.52728C1.69866 7.10591 0.25 10.6033 0.25 14.25C0.25 19.1562 2.51719 24.3563 6.8125 29.2891C7.95564 30.6014 9.17992 31.8408 10.4781 33H2.75C2.41848 33 2.10054 33.1317 1.86612 33.3661C1.6317 33.6005 1.5 33.9185 1.5 34.25C1.5 34.5815 1.6317 34.8995 1.86612 35.1339C2.10054 35.3683 2.41848 35.5 2.75 35.5H25.25C25.5815 35.5 25.8995 35.3683 26.1339 35.1339C26.3683 34.8995 26.5 34.5815 26.5 34.25C26.5 33.9185 26.3683 33.6005 26.1339 33.3661C25.8995 33.1317 25.5815 33 25.25 33ZM2.75 14.25C2.75 11.2663 3.93526 8.40483 6.04505 6.29505C8.15483 4.18526 11.0163 3 14 3C16.9837 3 19.8452 4.18526 21.955 6.29505C24.0647 8.40483 25.25 11.2663 25.25 14.25C25.25 23.1922 16.5828 30.6562 14 32.6875C11.4172 30.6562 2.75 23.1922 2.75 14.25ZM20.25 14.25C20.25 13.0139 19.8834 11.8055 19.1967 10.7777C18.5099 9.74988 17.5338 8.9488 16.3918 8.47575C15.2497 8.0027 13.9931 7.87893 12.7807 8.12009C11.5683 8.36125 10.4547 8.9565 9.58058 9.83058C8.7065 10.7047 8.11125 11.8183 7.87009 13.0307C7.62893 14.2431 7.7527 15.4997 8.22575 16.6418C8.6988 17.7838 9.49988 18.7599 10.5277 19.4467C11.5555 20.1334 12.7639 20.5 14 20.5C15.6576 20.5 17.2473 19.8415 18.4194 18.6694C19.5915 17.4973 20.25 15.9076 20.25 14.25ZM10.25 14.25C10.25 13.5083 10.4699 12.7833 10.882 12.1666C11.294 11.5499 11.8797 11.0693 12.5649 10.7855C13.2502 10.5016 14.0042 10.4274 14.7316 10.5721C15.459 10.7167 16.1272 11.0739 16.6517 11.5983C17.1761 12.1228 17.5333 12.791 17.6779 13.5184C17.8226 14.2458 17.7484 14.9998 17.4645 15.6851C17.1807 16.3703 16.7001 16.956 16.0834 17.368C15.4667 17.7801 14.7417 18 14 18C13.0054 18 12.0516 17.6049 11.3483 16.9017C10.6451 16.1984 10.25 15.2446 10.25 14.25Z" fill="black"/>
            </svg></Link>
        </div>       
         </div>

        <Footer />
      </div>
    

  );
}

export default PaymentSuccess;