import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Product({ product }) {
  const [hovered, setHovered] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  return (
    <Link to={`/product/${product.id}`}>
      <div className='card-col' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {product.media && product.media[0] && (
          <img
            style={{ width: "100%", marginBottom: "5px", position: "absolute", top: 0, left: 0, opacity: hovered ? 0 : 1 }}
            src={`${API_BASE_URL}${product.media[0].image1}`}
            alt={product.media[0].title}
          />
        )}
        {product.media && product.media[1] && (
          <img
            style={{ width: "100%", marginBottom: "5px", position: "absolute", top: 0, left: 0, opacity: hovered ? 1 : 0 }}
            src={`${API_BASE_URL}${product.media[1].image1}`}
            alt={product.media[1].title}
          />
        )}
        <div className='card-content'>
          {product.discount > 0 && <p className='cardOff'>{product.discount}%</p>}
          <p className='cardTitle'>{currentLanguage === 'ar' ? product.title_ar : product.title_en}</p>
          <p className='cardPrice'>{product.finalprice} AED</p>
        </div>
      </div>
    </Link>
  );
}

export default Product;
