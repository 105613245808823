import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Nopage from './pages/Nopage';
import About from './pages/About'; // Corrected import statement
import Checkout from './pages/Checkout';
import Product from './pages/Product';
import Register from "./pages/Register";
import Cart from "./pages/Cart";
import ProductList from "./pages/ProductList";
import Profile from "./pages/Profile";
import Verification from "./pages/Verification";
import ForgotPassword from "./pages/ForgetPassword";
import Contact from "./pages/Contact";
import OrderDetails from "./pages/OrderDetails";  
import SearchResults from "./pages/SearchResults";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancel from "./pages/PaymentCancel";
import ScrollToTop from './components/scrollToTop';
import Campaign from './pages/campaign'
import UserActivated from './pages/UserActivated'
import ActivationFailed from "./pages/ActivationFail";

import './I18n';



function App() {
  return (
    <div>
      <BrowserRouter>
      <ScrollToTop />

          <Routes>
            <Route index element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/About" element={<About />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Product/:id" element={<Product />} />
            <Route path="/Category/:title" element={<ProductList />} />
            <Route path="/AllProducts" element={<ProductList />} /> 
            <Route path='/search' element={<SearchResults />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Verification" element={<Verification />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/World/:id" element={<Campaign />} />
            <Route path="/Order/:id_order" element={<OrderDetails />} />
            <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
            <Route path="/PaymentCancel" element={<PaymentCancel />} />
            <Route path="/verification-success" element={<UserActivated />} />
            <Route path="/verification-fail" element={<ActivationFailed />} />
            <Route path="*" element={<Nopage />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
