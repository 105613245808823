import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../Actions/userActions';
import { Link, useNavigate } from 'react-router-dom';
import OrderList from '../components/OrdersList';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Footer from '../components/Footer';
import "./profile.css";
import ProfileInfo from '../components/ProfileInfo';
import Address from '../components/Address';
import Prefrences from '../components/Prefrences';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the default CSS for the confirm alert
import { confirmAlert } from 'react-confirm-alert';

function Profile() {
  const [selectedPanel, setSelectedPanel] = useState('orders');
  const dispatch = useDispatch();
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate();
  const [hasCheckedLogin, setHasCheckedLogin] = useState(false);

  useEffect(() => {
    if (!hasCheckedLogin && !userInfo) {

      const timeoutId = setTimeout(() => {
        if (!userInfo) {
          navigate('/');
        }
        setHasCheckedLogin(true); 
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [userInfo, navigate, hasCheckedLogin]);

  const handleLogout = () => {
    confirmAlert({
      title: 'Confirm?',
      message: 'Are you sure you want to log out',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
        dispatch(logout());
              }
            },
            {
              label: 'No'
            }
            ]
            });
              };

  const renderProfileInfo = () => {
    switch (selectedPanel) {
      case 'orders':
        return <OrderList />;
      case 'addresses':
        return <Address />;
      case 'profile':
        return <ProfileInfo />;
      case 'preferences':
        return <Prefrences />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className='userProfile'>
        <div className='userPanel'>
          <div className='userWelcome'>
            {userInfo ? (
              <p>Hi dear {userInfo.data.user.first_name}</p>
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <div
            className={`panelOptions ${selectedPanel === 'orders' ? 'selected' : ''}`}
            onClick={() => setSelectedPanel('orders')}
          >
            <p>My orders</p>
          </div>
          <div
            className={`panelOptions ${selectedPanel === 'addresses' ? 'selected' : ''}`}
            onClick={() => setSelectedPanel('addresses')}
          >
            <p>My addresses</p>
          </div>
          <div
            className={`panelOptions ${selectedPanel === 'profile' ? 'selected' : ''}`}
            onClick={() => setSelectedPanel('profile')}
          >
            <p>My profile</p>
          </div>
          <div
            className={`panelOptions ${selectedPanel === 'preferences' ? 'selected' : ''}`}
            onClick={() => setSelectedPanel('preferences')}
          >
            <p>Login preferences</p>
          </div>
          <p  className='userLogout' onClick={handleLogout}>
            <p>Log out</p>
          </p>
        </div>
        <div className='profileInfo'>
          {renderProfileInfo()}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;