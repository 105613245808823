import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import Products from '../components/Product';
import SizeSelector from '../components/SizeSelector';
import '../App.css';
import './Product.css';
import { Helmet } from 'react-helmet'; 
import { listProductDetails, listProducts } from '../Actions/productActions';
import { useParams } from 'react-router-dom';
import { addToCart, updateCartItemQty } from '../Actions/cartActions';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ShareExperience from '../components/ShareExperience';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function Product() {
  const { t, i18n } = useTranslation(); 
  const [qty, setQty] = useState(1); 
  const [showMessage, setShowMessage] = useState(false); 
  const [outOfStockMessage, setOutOfStockMessage] = useState(false); // State to handle out of stock message  

  const [messageClass, setMessageClass] = useState('fade-in-prod');
  const { id } = useParams();
  const dispatch = useDispatch();
  const productDetails = useSelector(state => state.productDetails);
  const { loading, error, product } = productDetails || {};
  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;
  const productList = useSelector(state => state.productList);
  const { products } = productList || {};
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';
  

  useEffect(() => {
    dispatch(listProductDetails(id));
    dispatch(listProducts());
  }, [dispatch, id]);

  const currentLanguage = i18n.language;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [activeSizeIndex, setActiveSizeIndex] = useState(0);

  useEffect(() => {
    setActiveColorIndex(0);
    setActiveSizeIndex(0);
  }, [product]);

  // Function to handle next image in the carousel
  const nextImage = () => {
    setCurrentImageIndex(prevIndex =>
      prevIndex === (filteredImages.length - 1) ? 0 : prevIndex + 1
    );
  };

  // Function to handle previous image in the carousel
  const prevImage = () => {
    setCurrentImageIndex(prevIndex =>
      prevIndex === 0 ? (filteredImages.length - 1) : prevIndex - 1
    );
  };

  // Function to handle click on product color
  const handleColorClick = (index) => {
    if (activeColorIndex !== index) {
      setActiveColorIndex(index);
      setActiveSizeIndex(0); 
      setCurrentImageIndex(0); 
    }
  };

  // Function to handle size change from the SizeSelector component
  const handleSizeChange = (index) => {
    setActiveSizeIndex(index);
  };

  // Ensure product and product.color are defined before filtering images
  const filteredImages = product?.media?.filter(item => item.color.title_en === product?.color?.[activeColorIndex]?.title_en) || [];
  const currentImage = filteredImages.length > 0 ? filteredImages[currentImageIndex % filteredImages.length] : null;

  // Check if the selected size is available in storage
  const isSizeAvailable = product?.storge?.some(item => (
    item.colorcount.color === product?.color?.[activeColorIndex]?.color &&
    item.size.options === product?.size?.[activeSizeIndex]?.options &&
    item.count > 0
  ));

  // Check if adding more to cart exceeds available count in storage
  const exceedsStorageCount = () => {
    const existingCartItem = cartItems.find(item => (
      item.sku === selectedSKU() && item.qty >= selectedStorageCount()
    ));
    return existingCartItem !== undefined;
  };

  // Retrieve selected SKU based on active indices
  const selectedSKU = () => {
    return product?.storge?.find(item => (
      item.colorcount.color === product?.color?.[activeColorIndex]?.color &&
      item.size.options === product?.size?.[activeSizeIndex]?.options
    ))?.SKU;
  };

  // Retrieve count from storage based on active indices
  const selectedStorageCount = () => {
    return product?.storge?.find(item => (
      item.colorcount.color === product?.color?.[activeColorIndex]?.color &&
      item.size.options === product?.size?.[activeSizeIndex]?.options
    ))?.count;
  };

  // Function to handle adding the item to the cart
  const addToCartHandler = () => {
    if (isSizeAvailable && !exceedsStorageCount()) {
      const sku = selectedSKU();
      const color = product?.color?.[activeColorIndex]?.title_en;
      const size = product?.size?.[activeSizeIndex]?.options;
      const price = product?.finalprice;
      const title_en = product?.title_en;
      const media = product?.media;
      const count = selectedStorageCount();
      dispatch(addToCart(product.id, qty, sku, color, size, price, title_en, count, media));
      setShowMessage(true);
      setMessageClass('fade-in-prod');
      setTimeout(() => {
        setMessageClass('fade-out-prod');
        setTimeout(() => {
          setShowMessage(false);
        }, 1900); // Match this to the fade-out duration
      }, 1000); // Time before starting fade-out
    } else {
      setOutOfStockMessage(true);
      setMessageClass('fade-in-prod');
      setTimeout(() => {
        setMessageClass('fade-out-prod');
        setTimeout(() => {
          setOutOfStockMessage(false);
        }, 1900); // Match this to the fade-out duration
      }, 1000); // Time before starting fade-out
    }
  };
  const updateQtyHandler = (sku, qty, count) => {
    if (qty > 0 && qty <= count) {
      dispatch(updateCartItemQty(sku, qty));
    } else if (qty === 0) {
      confirmAlert({
        title: t('Confirm to delete'),
        message: t('Are you sure you want to remove this item from your cart?'),
        buttons: [
          {
            label: t('Yes'),
            onClick: () => dispatch({ type: 'CART_REMOVE_ITEM', payload: sku })
          },
          {
            label: t('No'),
            onClick: () => {}
          }
        ]
      });
    } else if (qty > count) {
      // Show the out of stock message
      setOutOfStockMessage(true);
      setMessageClass('fade-in-prod');
      setTimeout(() => {
        setMessageClass('fade-out-prod');
        setTimeout(() => {
          setOutOfStockMessage(false);
        }, 1900); // Match this to the fade-out duration
      }, 1000); // Time before starting fade-out
    }
  };
  

  const renderAddToCartButton = () => {
    const cartItem = cartItems.find(item => item.product === product.SKU && item.sku === selectedSKU());
    if (cartItem) {
      return (
        <div  className={`add_btn ${!isSizeAvailable || exceedsStorageCount() ? 'disabled' : ''}`}>
         <p onClick={() => updateQtyHandler(cartItem.sku, cartItem.qty - 1, cartItem.count)}>-</p>
         <p className='btnQty'> {cartItem.qty}</p>
          <p onClick={() => updateQtyHandler(cartItem.sku, cartItem.qty + 1, cartItem.count)}>+</p>
        </div>
      );
    } else {
      return (
        <div
          className={`add_btn ${!isSizeAvailable || exceedsStorageCount() ? 'disabled' : ''}`}
          onClick={addToCartHandler}
        >
          {!isSizeAvailable || exceedsStorageCount() ? 'Out of Stock' : `Add to Cart`}
        </div>
      );
    }
  };
  


  if (loading) {
    return <div>{t('Loading')}...</div>;
  }

  if (error) {
    return <div>{t('Error')}: {error}</div>;
  }

  // Filter similar products (e.g., by category)
  const similarProducts = products?.filter(p => p.category[0]?.title_en === product?.category?.[0]?.title_en && p.id !== product.id);

  return (
    <div className='product-container'>
            <Helmet>
        <title>{product?.meta_title}</title>
        <meta name="description" content={product?.meta_description} />
        <meta name="keywords" content={product?.meta_keywords} />
        <link rel="canonical" href={`https://www.yourwebsite.com/product/${product?.id}`} />
      </Helmet>
      <div className='nav-pop'>
        <Popup />
        <Navbar />
      </div>
      <div className="space"></div>
      <div className="product">
        <div className='carousel'>
          <button className="prev" onClick={prevImage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.828 11.9998L15.778 16.9498L14.364 18.3638L8 11.9998L14.364 5.63577L15.778 7.04977L10.828 11.9998Z" fill="black" />
            </svg>
          </button>
          {currentImage && (
            <img
              className='product_picture'
              src={`${API_BASE_URL}${currentImage.image1}`}
              alt='Product'
            />
          )}
          <button className="next" onClick={nextImage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
              <path d="M4.94983 6.364L-0.000167847 1.414L1.41383 0L7.77783 6.364L1.41383 12.728L-0.000167847 11.314L4.94983 6.364Z" fill="black" />
            </svg>
          </button>
        </div>
        <div className='product_details'>
          <h2 className='product_name'>{currentLanguage === 'ar' ? product?.title_ar : product?.title_en}</h2>
          <p className='attributes'>{currentLanguage === 'ar' ? product?.descriptions_ar : product?.descriptions_en} </p>
          
          
          <div className='colors'>

            <p className='attributes'>{t('Style')}: {currentLanguage === 'ar' ? product?.style_ar : product?.style_en}</p>
            <p className='attributes'>{t('Gender')}: {product?.gender}</p>
            
            {product?.category && product?.category.length > 0 && (
              <p className='attributes'>{t('Category')}: {currentLanguage === 'ar' ? product?.category[0]?.title_ar : product?.category[0]?.title_en}</p>
            )}
          </div>
          <div className='colors'>
            {product?.color && product?.color.map((color, index) => (
              <div
                key={index}
                className={`prodcolorborder ${activeColorIndex === index ? 'active' : ''}`}
                style={{ borderColor: activeColorIndex === index ? color.color : 'transparent' }}
                onClick={() => handleColorClick(index)}
              >
                <div className='prodcolor' style={{ backgroundColor: color.color }}></div>
              </div>
            ))}
          </div>
          <div className='sizes'>
            {product?.size && product?.size.map((size, index) => (
              <p
                key={index}
                className={`size ${activeSizeIndex === index ? 'active' : ''} clickable`}
                onClick={() => handleSizeChange(index)}
              >
                {size.options}
              </p>
            ))}
          </div>
          <div className='prodbtns'>
  <div className='price'>
    {product.discount > 0 && <div className='prodOff'>{product.discount}% OFF</div>}
    {product?.price_without_discount > 0 && (
      <p className='offPrice'>{product?.price_without_discount} AED</p>
    )}
    {product?.finalprice} AED
  </div>
  {renderAddToCartButton()}
</div>

          <div className='prodbtnsPh'>
          <div className='price'>
          {product.discount > 0 && <div className='prodOff'>{product.discount}% OFF</div>}

  {product?.price_without_discount > 0 && (
    <p className='offPrice'>{product?.price_without_discount} AED</p>
  )}
  {product?.finalprice} AED
</div>

{renderAddToCartButton()}
          </div>
        </div>
      </div>
      <div className='prodDetname'>
        <p>{t('Details')}</p>
        <p>{t('How to Measure')}</p>
      </div>

      <div className='prodDetails'>
        <div className='detailsHalf'>
          <ul>
            <li>{t("Weight")} : <p className='detDetails'>{product?.weight}</p></li>
            <li>{t('Style')} : <p className='detDetails'>{currentLanguage === 'ar' ? product?.style_ar : product?.style_en}</p></li>
            <li>{t('Gender')} : <p className='detDetails'>{product?.gender}</p></li>
          </ul>
        </div>
        <div className='detailsHalf'>
          {product?.size && product?.size.length > 0 ? (
            <div className='selector'>
              <SizeSelector sizes={product?.size} onSizeChange={handleSizeChange} />
            </div>
          ) : (
            <div>Loading sizes...</div>
          )}
        </div>
      </div>
      {outOfStockMessage && (
  <div className={`out-of-stock-message ${messageClass}`}>
    {t('Product out of stock')}
  </div>
)}


      {showMessage && (
    <div className={`add-to-cart-message ${messageClass}`}>
        {t('Product Added to Cart!')}
        
    </div>
)}

      {/* Similar Products Section */}
      <div className='similar-products'>
        <h3>{t('Similar Products')}</h3>
        <div className='similar-products-list'>
          {similarProducts.map(similarProduct => (
  <div className='cardSim'>
        <Products product={similarProduct} />
  </div>
          ))}
        </div>
      </div>
      <ShareExperience /> 

      <Footer />
    </div>
  );
}

export default Product;
