import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoryList,listCampaigns } from '../Actions/productActions';
import { motion as m } from 'framer-motion';
import './menu.css';
import { Link, useNavigate } from 'react-router-dom';
import { userDetails } from '../Actions/userActions';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const overlayVariants = {
  open: { opacity: 1, display: 'flex' },
  closed: { opacity: 0, transitionEnd: { display: 'none' } },
};

const menuVariants = {
  open: { left: '0' },
  closed: { left: '-1000px' },
};

const Menu = ({ isOpen, toggleMenu }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { error, loading, categories } = useSelector((state) => state.categoryList);
  const { userInfo } = useSelector((state) => state.userLogin);
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const navigate = useNavigate();
  const {error: camperror, loading : camploading, campaigns} = useSelector((state) => state.CampaignList);
  const [searchQuery, setSearchQuery] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


  useEffect(() => {
    dispatch(categoryList());
    dispatch(listCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(userDetails(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  const handleSearch = (e) => {
    e.preventDefault();
    toggleMenu(); // Close the menu
    navigate(`/search?query=${searchQuery}`);
  };

  let categoryItems;

  if (loading) {
    categoryItems = <p>Loading...</p>;
  } else if (error) {
    categoryItems = <p>Error: {error}</p>;
  } else {
    const currentLanguage = i18n.language;
    categoryItems = categories.map((category) => (
      <Link
        key={category.id}
        onClick={toggleMenu}
        to={`/Category/${category.title_en}`}
        className='categories'
      >
        <img className='catIco' alt={category.title} src={`${API_BASE_URL}${category.image}`} />
        <p>{currentLanguage === 'ar' ? category.title_ar : category.title_en}</p>
      </Link>
    ));
  }
  let campaignItems;
  if (camploading) {
    campaignItems = <p>Loading...</p>;
  } else if (camperror) {
    campaignItems = <p>Error: {camperror}</p>;
  } else if (Array.isArray(campaigns.campaign)) {
    const currentLanguage = i18n.language;
    campaignItems = campaigns.campaign.map((campaign) => (
      <Link
        key={campaign.id}
        onClick={toggleMenu}
        to={`/World/${campaign.id}`}
        className='infos'
      >
        <p>{currentLanguage === 'ar' ? campaign.title_ar : campaign.title_en}</p>
      </Link>
    ));
  } else {
    campaignItems = <p>No campaigns found.</p>;
  }
  return (
    <m.div
    
      animate={isOpen ? 'open' : 'closed'}
      variants={overlayVariants}
      transition={{ duration: 0.75 }}
      className={`overlay ${isOpen ? 'menu-overlay' : ''}`}
    >
      <m.div
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
        transition={{ duration: 0.75 }}
        className='menu1'
      >
        <div className='left-panel'>
          <p style={{cursor:'pointer', width:"60px"}} onClick={toggleMenu}>X {t('close')}</p>
          
          <div className='categoryList'>
          <div className='search'>
            <form onSubmit={handleSearch} className='search-form'>
              <input
                type='text'
                placeholder={t('Search')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className='search-input'
              />
              <button type='submit' className='search-button'>
                <img src='/images/search_icon.png' alt='search' />
              </button>
            </form>
          </div>
            <h3>{t('shop')}</h3>
            {categoryItems}


          </div>
        </div>
        <div className='right-panel'>
          <LanguageSwitcher />
        
          <div className='infoList'>
            <h3>{t('info')}</h3>
            <div className='infos'>
              <Link onClick={toggleMenu} className='infoP' to='/'>
                {t('Home')}
              </Link>
              <Link onClick={toggleMenu} className='infoP' to='/about'>
                {t('About Us')}
              </Link>
              <Link onClick={toggleMenu} className='infoP' to='/contact'>
                {t('Contact Us')}
              </Link>
            </div>
            {campaignItems && campaignItems.length > 0 && (
          <h3>{t('Parvari World')}</h3>
        )}
            <div className='infoP'>
            {campaignItems}
                 </div>
          </div>
        </div>
      </m.div>
    </m.div>
  );
};

export default Menu;
