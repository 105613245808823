import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../components/Navbar';
import Popup from '../components/Popup';
import { register } from '../Actions/userActions';
import './register.css';
import Footer from '../components/Footer';
import Login from '../components/Login';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Register() {
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [re_password, setRe_password] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userRegister = useSelector((state) => state.userRegister);
  const { userRegisterInfo, error } = userRegister;
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const { t,i18n} = useTranslation();
  const userId = userRegisterInfo && userRegisterInfo.data.user.UUID;

  useEffect(() => {
    if (userInfo) {
      setIsLoginOpen(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (userRegisterInfo) {
      navigate(`/verification?user_id=${userId}`);
    }
  }, [navigate, userRegisterInfo]);

  const toggleLogin = () => setIsLoginOpen((prev) => !prev);

  const validateForm = () => {
    const errors = {};

    if (!first_name.trim()) {
      errors.first_name = 'This field is required';
    }

    if (!last_name.trim()) {
      errors.last_name = 'This field is required';
    }

    if (!email.trim()) {
      errors.email = 'This field is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.emailFormat = 'Invalid email address';
    }

    if (!password.trim()) {
      errors.password = 'This field is required';
    } else if (password.length < 8 || password.length > 20) {
      errors.passwordLength = 'Password should be between 8 and 20 characters';
    } else if (!/[A-Z]/.test(password) || !/\d/.test(password)) {
      errors.passwordFormat = 'Password should contain at least an uppercase letter and a digit';
    }

    if (!re_password.trim()) {
      errors.re_password = 'This field is required';
    }

    if (password !== re_password) {
      errors.match = 'Passwords do not match.';
    }

    if (!isAgreementChecked) {
      errors.agreement = 'Please accept the license and agreement';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleAgreementChange = (e) => {
    setIsAgreementChecked(e.target.checked);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(register(first_name, last_name, email, password, re_password));
    }
  };

  return (
    <div>
      <div className="nav-pop">
        <Popup />
        <Navbar />
      </div>
      <h2 className="welcome">WELCOME</h2>
      <div className="registerPanel">
        <div className="signUp">
          <h2>Sign Up</h2>
          <form onSubmit={submitHandler}>
            <p>First Name</p>
            <input
              className={`registerinput ${errors.first_name && 'errorReg'}`}
              type="text"
              value={first_name}
              onChange={(e) => setFirst_name(e.target.value)}
              placeholder={errors.first_name}
            />

            {/* Last Name */}
            <p>Last Name</p>
            <input
              className={`registerinput ${errors.last_name && 'errorReg'}`}
              type="text"
              value={last_name}
              onChange={(e) => setLast_name(e.target.value)}
              placeholder={errors.last_name}
            />

            {/* Email */}
            <p>E-mail</p>
            <input
              className={`registerinput ${errors.email && 'errorReg'} ${errors.emailFormat && 'errorReg'}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={errors.email}
            />
            <p className="fieldError">{errors.emailFormat}</p>

            {/* Password */}
            <p>Password</p>
            <input
              className={`registerinput ${errors.password && 'errorReg'} ${errors.passwordFormat && 'errorReg'} ${
                errors.passwordLength && 'errorReg'
              }`}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={errors.password}
            />
            <p className="fieldError">{errors.passwordLength}</p>
            <p className="fieldError">{errors.passwordFormat}</p>

            {/* Confirm Password */}
            <p>Confirm Password</p>
            <input
              className={`registerinput ${errors.re_password && 'errorReg'} ${errors.match && 'errorReg'}`}
              type="password"
              value={re_password}
              onChange={(e) => setRe_password(e.target.value)}
              placeholder={errors.re_password}
            />
            <p className="fieldError">{errors.match}</p>

            <div>
              <p htmlFor="consent">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={isAgreementChecked}
                  onChange={handleAgreementChange}
                />
                I accept the license and agreement
              </p>
              <p className="fieldError">{errors.agreement}</p>
            </div>

            <div>
              <p htmlFor="consent">
                <input type="checkbox" id="consent" name="consent" />
                I approve to receive commercial electronic mail to my contact info from US and the data I have
                provided above can be used by US in line with this purpose within the scope of Acknowledgement Letter
                Regarding The Protection of Personal Data.
              </p>
            </div>

            <button type="submit">Register</button>

            {error && <div className="error-container">{error}</div>}

          </form>
          <p style={{cursor:"pointer"}} onClick={toggleLogin}>{t('Already have an account?')}</p>
        </div>

        <div className="welcomePanel">
          <img className="logoEmpty" src="./images/logoempty.png" />
          <div className="join">
            <h3>WHEN YOU JOIN US</h3>
            <div>
              <img src="./images/ring.png" /> <p>{('We update you about what is new in Parvari Fashion')}</p>
            </div>
            <div>
              <img src="./images/tag.png" /><p>{t('You get a priority on campaigns and discounts.')}</p>
            </div>
            <div>
              <img src="./images/calender.png" /><p>{t('We inform you first about events and campaigns')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="space"></div>
      <Footer />
      <Login isOpen={isLoginOpen} toggleLogin={toggleLogin} />
    </div>
  );
}

export default Register;
