import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelOrder } from '../Actions/cartActions';

const CancelOverlay = ({ onClose, delivered, delivering, orderId, token}) => {
  const dispatch = useDispatch();
  const [reason_for_cancellation, setReasonForCancellation ]= useState('');
  const [shippingـmethod, setShippinhMethod ]= useState('');
  const [tracking_code, setTrackingCode ]= useState('');

  const handleConfirm = async () => {
    if (delivering) {
      // Do not allow cancellation if the order is in delivering stage
      return;
    }

    // Dispatch cancelOrder action
    try {
      await dispatch(cancelOrder(token, orderId, reason_for_cancellation, shippingـmethod, tracking_code)); // Replace with actual token and id_order
      console.log('Order canceled successfully');
    } catch (error) {
      console.error('Error canceling order:', error);
      // Handle error, show message to user, etc.
    }

    onClose(); // Close the overlay
  };

  return (
    <div className="overlayCancel">
      <div className="cancel-overlay">
        <h2>{delivering ? 'Cannot Cancel Order' : delivered ? 'Return Order' : 'Cancel Order'}</h2>
        {delivering ? (
          <p className="message">You cannot cancel the order when it is being sent.</p>
        ) : delivered && (
          <div className="textarea-container">
            <textarea
              id="reason"
              className="textarea-input"
              value={reason_for_cancellation}
              onChange={(e) => setReasonForCancellation(e.target.value)}
              rows={5}
              placeholder="Enter your reason for returning the order..."
            />
            <div style={{display:'flex',flexDirection:'column'}}>
            <span>Shipping method</span>
            <input
                      className="cancel-input"
            placeholder='Tell us how you sent the order back to us'
               onChange={(e) => setShippinhMethod(e.target.value)}
            />
            <span>Shhipping info</span>
            <input
                                  className="cancel-input"
            placeholder='Give us any ifno you think we might need to recieve package'
               onChange={(e) => setTrackingCode(e.target.value)}
            /></div>
          </div>
        )}
        {!delivering && (
          <div className="button-container">
            <button className="cancel-btn" onClick={onClose}>Cancel</button>
            <button className="confirm-btn" onClick={handleConfirm}>Confirm</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelOverlay;
