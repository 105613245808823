import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listOrder } from '../Actions/cartActions';
import { listProducts } from '../Actions/productActions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function OrderList() {
  const dispatch = useDispatch();

  // Selecting necessary state variables
  const { userInfo } = useSelector((state) => state.userLogin);
  const token = userInfo && userInfo.data ? userInfo.data.token : null;
  const { orders, loading: orderLoading, error: orderError } = useSelector((state) => state.ordersList);
  const { products, loading: productLoading, error: productError } = useSelector((state) => state.productList);
  const {t, i18n } = useTranslation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.parvari.ae/';

  // Fetch orders and products when component mounts
  useEffect(() => {
    if (token) {
      dispatch(listOrder(token));
      dispatch(listProducts());
    }
  }, [dispatch, token]);

  // Function to get product details based on SKU and colorId
  const getOrderProduct = (sku, colorId) => {
    const product = products?.find((p) =>
      p.storge.some((storage) => storage.SKU === sku)
    );

    if (product) {
      const media = product.media?.find(
        (mediaItem) => mediaItem.color.id === colorId
      );
      return {
        title: product.title_en,
        image: media ? media.image1 : null
      };
    }
    return { title: 'Product title not available', image: null };
  };

  if (orderLoading || productLoading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching data
  }

  if (orderError) {
    return <div>Error: {orderError}</div>; // Handle error state for orders
  }

  if (productError) {
    return <div>Error: {productError}</div>; // Handle error state for products
  }

  // Check if orders exist and are not an empty array
  const orderDetails = orders?.data?.['order detail'];
  const hasOrders = orderDetails && orderDetails.length > 0;

  // Group order details by order_id
  const groupedOrders = hasOrders ? orderDetails.reduce((acc, orderDetail) => {
    const orderId = orderDetail.order.order_id;
    if (!acc[orderId]) {
      acc[orderId] = {
        order: orderDetail.order,
        details: []
      };
    }
    acc[orderId].details.push(orderDetail);
    return acc;
  }, {}) : {};

  return (
    <div className="ordersCont order-list">
      {hasOrders ? (
        Object.keys(groupedOrders).map((orderId) => {
          const { order, details } = groupedOrders[orderId];
          return (
            <div key={orderId} className="order-item">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>Shipped to "{order.shipping_address?.title_address || 'N/A'}" address</h3>
                <Link
                  style={{ margin: '3%', textDecoration: 'none', color: '#00CCA7' }}
                  to={`/Order/${order.order_id}`}
                >
                  details
                </Link>
              </div>
              <p>Shipping Address: {order.shipping_address?.address || 'N/A'}</p>
              <div className="order-details">
                {details.slice(0, 3).map((detail, index) => {
                  const { SKU, colorcount } = detail.storage;
                  const { title, image: productImage } = getOrderProduct(SKU, colorcount.id);
                  return (
                    <div key={index} className="order-detail-item">
                      {productImage ? (
                        <img className='orderImgList' src={`${API_BASE_URL}${productImage}`} alt={title} />
                      ) : (
                        <p>{t('Product image not available')}</p>
                      )}
                    </div>
                  );
                })}
                {details.length > 3 && (
                  <div className="additional-orders">
                    <p>{details.length - 3}+</p>
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className='order-item'><p className='no-orders'>{t('No orders placed yet')}</p></div>
      )}
    </div>
  );
}

export default OrderList;
